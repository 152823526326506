import React, { useEffect, useState } from 'react'
import './Course.css'
import SideBar from '../../component/sidebar/SideBar'
import { useAlert } from 'react-alert'
import { CircularProgress } from "@mui/material";
import { NavLink, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { apilink, path } from '../../data/fdata';
import Cookies from 'js-cookie';
import axios from 'axios';
import SelectInput from '../../component/multi/SelectInput';
import DynamicInputFields from './DynamicInputFields';

const Courses = () => {
  const token = Cookies.get('_edushark_admin_access_token');
  const his = useHistory();
  const alert= useAlert()

 const [semDetails,setSemDetails] = useState([{ semtitle: '',semfees:''}]);
  const [loading, setLoading] = useState(false);
  const [courseId, setCourseId] = useState("");
  const [collegeId, setCollegeId] = useState("");
  const [collageName, setCollegeName] = useState("");
  const [totalFees, setTotalFees] = useState("");

  const [collegeList,setCollegeList] = useState([])
  const [courseList,setCourseList] = useState([])
  const [subjects,setSubjects] = useState([])

  const [pageloading, setPageLoading] = useState(false);


  useEffect(async() => {
    if(token) {
      setPageLoading(true)
        const res=await axios.get(`${apilink}/api/admin/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(!res.data.success)
          {
            Cookies.remove('_edushark_admin_access_token');
           localStorage.removeItem('_edushark_admin_access_login');
          console.clear();
          window.location.href = '/login';
          }else{
           
            if(!res.data.userInfo?.isAdmin)
            {
              window.location.href = '/login';
            }
  
          }
          setTimeout(() => {
            setPageLoading(false)
          }, 1500);
         
    }else{
      his.push("/login")
    }
  }, []);


  const getcollegedata=async()=>{

    const res=await axios.get(`${apilink}/api/college/getColleges`)
    // console.log(res.data)
  
    if(res.data.success)
    {
      setCollegeList(res.data.result)
    }else{
      alert.error(res.data.msg)
    }
  
  }
  
  const getcoursedata=async()=>{
  
    const res=await axios.get(`${apilink}/api/course/getcourses`)
    // console.log(res.data)
  
    if(res.data.success)
    {
      setCourseList(res.data.result)
    }else{
      alert.error(res.data.msg)
    }
  
  }


  const addCollegeToCourse=async(e)=>{
    e.preventDefault()
    setLoading(true)

    // const updatedArray = subjects.map((obj) => {
    //   const { label, ...rest } = obj; // Use destructuring to remove the 'age' property
    //   return rest.value; // Return the object without the 'age' property
    // });
    
    // console.log(updatedArray);

    const data={
      courseId,
      collegeId,
      collageName,
      subjects,
      totalprice:totalFees,
      collegefees:semDetails
    }
    const res=await axios.post(`${apilink}/api/course/updatecourse`,data,{
      headers: {
            
        Authorization: token,
      }
    })
    
    if(res.data.success)
    {
      alert.success(res.data.msg)
      setCourseId("")
      setCollegeId("")
      setCollegeName("")
      setSubjects([])
      setTotalFees("")
      setSemDetails([{ semtitle: '',semfees:''}])  

      getcoursedata()

    }else{
      alert.error(res.data.msg)
    }

    // console.log(data)
    
    setLoading(false)

  }

  const updateActiveStatus=async(id)=>{
    setPageLoading(true)

    const res=await axios.get(`${apilink}/api/course/updateActiveStatus/${id}`,{
      headers: {
            
        Authorization: token,
      }
    })
    // console.log(res.data)
    if(res.data.success)
    {
      

      getcoursedata()
      setTimeout(() => {
        setPageLoading(false)
        alert.success(res.data.msg)
      }, 1500);
    }else{
      setTimeout(() => {
        setPageLoading(false)
        alert.error(res.data.msg) 
      }, 1500);
      
    }
  }

  const updateSeenStatus=async(id) => {
    
    setPageLoading(true)

    const res=await axios.get(`${apilink}/api/course/updateSeenStatus/${id}`,{
      headers: {
            
        Authorization: token,
      }
    })
    // console.log(res.data)
    if(res.data.success)
    {
      

      getcoursedata()
      setTimeout(() => {
        setPageLoading(false)
        alert.success(res.data.msg)
      }, 1500);
    }else{
      setTimeout(() => {
        setPageLoading(false)
        alert.error(res.data.msg) 
      }, 1500);
      
    }
  }
  useEffect(()=>{
    getcollegedata()
    getcoursedata()
  },[])

  useEffect(()=>{
    if(courseId && collageName)
    {
      let arr=courseList.filter((v)=>v._id==courseId)
      // console.log(arr)
      let newarr=arr[0]?.cou_colleges?.filter((v)=>v.collegeId == collegeId )
      // console.log(newarr)
      if(newarr.length)
      {
        setSemDetails(newarr[0].collegefees  )
        setSubjects(newarr[0].subjects)
        setTotalFees(newarr[0].totalprice)
      }else{
        setSemDetails([{ semtitle: '',semfees:''}])  

        setSubjects([])
        setTotalFees("")
      }
      
    }

  },[courseId,
    collegeId,
    collageName])
  return (
    <>
    
    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
          <div className="container-fluid">
          
            <div className="row">
              <div className="col-12">
                <div className="card p-3">
                <h4 className='text-custom mb-4'>Add Colleges to Courses</h4>
                  <form onSubmit={addCollegeToCourse}>
                  <div class="form-row">
                     <div className="form-group col-lg-6 col-md-6">
                       <label for="address">Choose Course Name:</label>
                       <select
                          class="form-control form_h"
                          value={courseId}
                          onChange={(e) => setCourseId(e.target.value)}
                          required
                        >
                          <option selected hidden value="">
                            --Choose--
                          </option>
                          {
                            courseList?.map((val,ind)=>{
                              return(
                                <>
                                
                                <option key={ind}  value={val._id}>
                                  {val.cou_name}
                                </option>
                                </>
                              )
                            })
                          }
                        </select>
                       
                     </div>
                     <div className="form-group col-lg-6 col-md-6">
                       <label htmlFor="">Choose College Name</label>

                       <select
                          class="form-control form_h"
                          value={collegeId}
                          onChange={(e) => {
                            setCollegeId(e.target.value)
                            let ar=collegeList.filter((v)=>v._id==e.target.value)
                            setCollegeName(ar[0].col_name)
                          }}
                          required
                        >
                          <option selected hidden value="">
                            --Choose--
                          </option>
                          {
                            collegeList?.map((val,ind)=>{
                              return(
                                <>
                                
                                <option key={ind}  value={val._id}>
                                  {val.col_name}
                                </option>
                                </>
                              )
                            })
                          }
                        </select>
                       
                     </div>
                   </div>
                   <div class="form-row">
                     <div className="form-group col-lg-6 col-md-6">
                       <label for="address">Choose Subjects:</label>
                       <SelectInput  selected={subjects} setSelected={setSubjects} />
                      
                       </div>
                       <div className="form-group col-lg-6 col-md-6">
                       <label for="address">Add Course Fees:</label>

                       <input
                         type="text"
                         placeholder="Add Course Fees"
                         className="form-control form_h"
                         name="totalFees"
                         value={totalFees}
                         onChange={(e) => setTotalFees(e.target.value)}
                         required
                       />
                       
                       
                     </div>
                       </div>
                   <DynamicInputFields  semesterFields={semDetails} setSemesterFields ={setSemDetails} />

                   
                   <div className="text-right">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Submit
                      </button>
                    </div>
                    
                    {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} />
                      </div>
                    )}
                  </form>
                </div>
               {
                courseList.length > 0 && (
                  <>
                  
                  
                  <div className="card p-3 mt-5">
                  <h4 className='text-custom'>All Courses</h4>
                  <div class="table-responsive  ">
          <table class="table table-borderless dash">
            <thead>
              <tr>
                <th className="w_35">CourseDetails</th>
                <th className="w_20">Price</th>
                <th className="w_20">Duration</th>
                <th className="w_60">Description</th>
               
                <th className="w_60">Semester Details</th>
                <th className="w_60">College Details</th>

                
              </tr>
            </thead>
            <tbody className=''>
              {
                courseList?.map((val,ind)=>{
                  return(
                    <>
                    <tr>
                
                    <td className=''>
                    <img src={val.cou_image} alt="" className='big_td_img' />
           <div className='ml-2 text-center'>
             <p className=' m-0 two_p'>{val.cou_name}</p>

            <div className="text-center">
            <NavLink to={`/edit-course/${val._id}`} className='badge badge-primary ' >View</NavLink> 
             <p className='m-0'>{val.cou_isActive ? <span className='badge badge-success cur' onClick={()=>updateActiveStatus(val._id)}>Update Status</span>  : <span className='badge badge-danger cur' onClick={()=>updateActiveStatus(val._id)}>Update Status</span>}</p>
             <p className='m-0'>{val.cou_isSeen ? <span className='badge badge-success cur' onClick={()=>updateSeenStatus(val._id)}>Seen</span>  : <span className='badge badge-danger cur' onClick={()=>updateSeenStatus(val._id)}>Hide</span>}</p>

            </div>

           </div>
            </td>
            <td>₹{val.cou_price}</td>
            <td>{val.cou_duration} years</td>
            <td>
              <p className='big_p'>{val.cou_description}</p>
            </td>
            <td>
              {
                val?.cou_sem_prices?.map((v,i)=>{
                  return (
                    <>
                    
                    <p className='m-0'>{v.semtitle}&nbsp; - &nbsp; ₹{v.semfees}</p>
                    </>
                  )
                })
                }
                </td>
            <td>
{
  val.cou_colleges?.map((v,index)=>{
    return (
      <>
      <p className='m-0 mb-2' key={index}>{v.collageName} {val.cou_colleges.length !=index+1 ? " , ":" ."} &nbsp; <span className='badge badge-primary cur' data-toggle="modal"
                            data-target={`#myModal${v._id}`}> view </span> </p>
      
      <div class="modal fade" id={`myModal${v._id}`}>
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">View Details</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div class="modal-body">
              {
                v?.subjects?.length > 0  && (
                  <>
                  <h5>Subjects</h5>
              {
                v?.subjects?.map((value, index) =>{
                  return (
                    <>
                    <span>{value.label}</span> {v.subjects.length !=index+1 ? " , ":" ."} 
                    </>
                  )
                })

              }
                  </>
                )
              }
               {
                v?.collegefees?.length > 0  && (
                  <>
                  <h5 className='mt-4'>College Fees</h5>
              {
                v?.collegefees?.map((value, index) =>{
                  return (
                    <>
                    <p className='m-0 mb-2'>{value.semtitle}&nbsp; - &nbsp; ₹{value.semfees} {v.collegefees.length !=index+1 ? " , ":" ."} </p> 
                    </>
                  )
                })

              }
                  </>
                )
              }
             
            
            </div>
          </div>
        </div>
      </div>
      </>
    )
  })
}


            </td>

               
              </tr>
                    </>
                  )
                })
              }
             
            </tbody>
          </table>
        </div>

                </div>
                  </>
                )
               }



                </div>
                </div>
                </div>

         
           
          </div>
        </div>
      </div>

      {
        pageloading && <>
<div className="pageloading">
<div class="loadingio-spinner-bean-eater-ghxsb80unx"><div class="ldio-xz472s6kr1">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div>
</div>


        </>
      }
    
    </>
  )
}

export default Courses