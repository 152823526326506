import React from "react";
import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";

const SelectInput = ({selected, setSelected}) => {
  const options = [
    { label: "Bengali", value: "Bengali" },
    { label: "English", value: "English" },
    { label: "Sanskrit", value: "Sanskrit" },
    { label: "Arabic", value: "Arabic" },
    { label: "Mathematics", value: "Mathematics" },
    { label: "Life Science", value: "Life Science" },
    { label: "Physical Science", value: "Physical Science" },
    { label: "History", value: "History" },
    { label: "Geography", value: "Geography" },
    { label: "Education", value: "Education" },
    { label: "Political Science", value: "Political Science" },
    { label: "Computer Science", value: "Computer Science" },
    { label: "Commerce", value: "Commerce" },
    { label: "Economics", value: "Economics" },
    { label: "Music", value: "Music" },
    { label: "Sociology", value: "Sociology" },
    { label: "Pear 🍐", value: "pear", disabled: true },
  ];

  
  return (
    <>
      <MultiSelect
        options={options}
        value={selected}
        onChange={setSelected}
        labelledBy={"Select"}
        isCreatable={true}
      />
    </>
  );
};

export default SelectInput;
