import React, { useEffect, useState } from 'react'
import './Gallery.css'
import SideBar from '../../component/sidebar/SideBar'
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { apilink, path } from '../../data/fdata';
import axios from 'axios';
import { useAlert } from 'react-alert';
import { CircularProgress } from '@mui/material';

const Gallery = () => {
    const [pageloading, setPageLoading] = useState(false);
    const token = Cookies.get('_edushark_admin_access_token');
    const his = useHistory();
    const alert=useAlert()
const [loading,setLoading] =useState(false)

const [title,setTitle] =useState("")
const [galImg,setGalImg] =useState([])
const [galleryList,setGalleryList] = useState([])

  
    useEffect(async() => {
      if(token) {
        setPageLoading(true)
          const res=await axios.get(`${apilink}/api/admin/authVerify`,{
              headers: {
                Authorization: token,
              },
            })
            // console.log(res.data)
            if(!res.data.success)
            {
              Cookies.remove('_edushark_admin_access_token');
             localStorage.removeItem('_edushark_admin_access_login');
            console.clear();
            window.location.href = '/login';
            }else{
             
              if(!res.data.userInfo?.isAdmin)
              {
                window.location.href = '/login';
              }
    
            }
            setTimeout(() => {
              setPageLoading(false)
            }, 1500);
           
      }else{
        his.push("/login")
      }
    }, []);

    const getAllgallery=async()=>{
        const res=await axios.get(`${apilink}/api/gallery/getAllgallery`)
        // console.log(res.data)
    
        if(res.data.success){
            setGalleryList(res.data.result)
    
        }else{
            alert.error(res.data.msg)
        }
    
    }


    const submitGallery=async(e)=>{
        e.preventDefault()
  setLoading(true)

  let formData = new FormData();
  Array.from(galImg).forEach(image => {
    formData.append("file", image);
});

  // formData.append("file", galImg);

  const res = await axios.post(`${apilink}/api/file/upload`, formData, {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: token,
    },
  });
  // console.log(res.data)
  if(res.data.success)
  {
    const data={
        gal_title:title,
        gal_image:res.data.imageUrls
  
    }
    const ress=await axios.post(`${apilink}/api/gallery/postgallery`,data,{
      headers: {
        
        Authorization: token,
      },
    })
    if(ress.data.success)
    {
      
      alert.success("Gallery added successfully")
      setGalImg([])
      setTitle("")
      getAllgallery()
    //   setTimeout(() => {
    //     window.location.reload()
    //   }, 2000);
    }else{
      alert.error(ress.data.msg)

    }
  
    

  }else{
    alert.error(res.data.msg)
  }

 


  setLoading(false)
    }

    const handelimage = (e) => {
      const { files } = e.target;
      let f = false;
      let filetype = ['image/jpeg', 'image/jpg', 'image/png'];
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          // console.log(files[i].type);
          if (files[i].size > 1000000) {
            f = true;
            // console.log('ok');
          }
  
          if (!filetype.includes(files[i].type)) {
            f = true;
            // console.log('hi');
          }
        }
        if (f) {
          // console.log(f);
          alert.error(
            'Upload images with proper file size (1MB) & extension ( jpg, png , jpeg)'
          );
          f = false;
        }else{
          // console.log();
          setGalImg(e.target.files)
        }
      }
    };

    // const handelFile = (e) => {
    //     const { files } = e.target;
    //     // console.log(files[0]); files[0].type === 'application/pdf'
    //     if (files.length > 0) {
    //       if (files[0].size > 2000000) {
    //         alert.error(` File should be less then 2 MB`);
           
    //       } else if (
    //         files[0].type === "image/jpeg" ||
    //         files[0].type === "image/jpg" ||
    //         files[0].type === "image/png"
    //       ) {
           
    //         setGalImg(files[0]);
           
    //       } else {
            
    //         alert.error(`File extension should be only jpg, png , jpeg`);
    //       }
         
    //     }
    //   };



      useEffect(()=>{
        getAllgallery()
      },[])

      const deletegallery=async(id)=>{

        const res=await axios.get(`${apilink}/api/gallery/deletegallery/${id}`,{
            headers: {
        
                Authorization: token,
              },
        })
        // console.log(res.data)
        if(res.data.success)
        {
            alert.success(res.data.msg)
            let ar=galleryList.filter((v)=>v._id != id)
            setGalleryList(ar)
        }else{
            alert.error(res.data.msg)
        }
      }

  return (
    <>
    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                <div className="dd_flex">
                        <h3 className='text-custom'>Gallery</h3>
                        <button className='sm_btn' data-toggle="modal"
                            data-target='#myModal'>Upload Gallery</button>

                        <div class="modal fade" id='myModal'>
        <div class="modal-dialog modal modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Upload Gallery</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div class="modal-body">
                <form onSubmit={submitGallery}>

                <div className="form-group ">
                       <label for="address">Gallery Title:</label>
                       <input
                         type="text"
                         placeholder="Enter gallery title"
                         className="form-control form_h"
                         name="title"
                         value={title}
                         onChange={(e) => setTitle(e.target.value)}
                         required
                       />
                     </div>

                     <div className="form-group ">
                       <label for="address">Upload Gallery Images:</label>
                       <input
                         type="file"                         
                         className="form-control form_h"
                         name="image"
                         multiple
                         accept=".png, .jpeg , .jpg"
                        
                         onChange={handelimage}
                         required
                       />
                     </div>
                     <div className="text-center">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Submit
                      </button>
                    </div>
                    
                    {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} />
                      </div>
                    )}

                </form>
             
            
            </div>
          </div>
        </div>
      </div>
                    </div>
                   {
                    galleryList.length > 0 ?(
                        <>
                        
                        <div className="gallery_flex mt-5">
                       {
                        galleryList.map((val,ind)=>{
                            return (
                                <>
                                
                                
                                <div className="gallery_flex_item " key={ind}>
                                  {
                                    val.gal_image?.length > 0  && (
                                      <>
                                       <img src={val.gal_image[0]} alt="" />
                                      
                                      </>
                                    )
                                  }
                           
                            <div className="gal_title">
                                <p className='m-0'>{val.gal_title}</p>
                            </div>
                            <div className="delete_btn" onClick={()=>deletegallery(val._id)}>
                                <i className='fa fa-trash' ></i>       
                            </div>

                        </div>
                                </>
                            )
                        })
                       }
                       
                    </div>
                        
                        </>
                    ):(
                        <>
                        <div className="text-center p-2 pt-5 mt-5">
                            <h5>No Item Found</h5>
                        </div>
                        
                        </>
                    )
                   }

                </div>
              </div>
            </div>
         
           
          </div>
        </div>
      </div>
      {
        pageloading && <>
<div className="pageloading">
<div class="loadingio-spinner-bean-eater-ghxsb80unx"><div class="ldio-xz472s6kr1">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div>
</div>


        </>
      }
    
    </>
  )
}

export default Gallery