import React from 'react'
import './PurchesCourse.css'
import { useState } from 'react';
import { useAlert } from 'react-alert';
import Cookies from 'js-cookie';
import ReactPaginate from 'react-paginate';
import { CircularProgress } from "@mui/material";
import {NavLink , useHistory } from 'react-router-dom/cjs/react-router-dom.min'


import axios from 'axios'
import SideBar from '../../component/sidebar/SideBar';
import { apilink, path } from '../../data/fdata';
import { useEffect } from 'react';

const PurchesBook = () => {
    const token = Cookies.get('_edushark_admin_access_token');
    const his = useHistory();
     const alert= useAlert()
     const [book_List, setBook_List] = useState([1]);
     const [pageloading, setPageLoading] = useState(false);

     const [searchText,setSearchText]=useState("")
     const [loading, setLoading] = useState(false);
     const [popup, setPopup] = useState(false);

     const [pageNo, setPageNo] = useState(0);

  const perpage = 18;
  const pagevisit = pageNo * perpage;

  const dataall = book_List.slice(pagevisit, pagevisit + perpage);
  const boxno = Math.ceil(book_List.length / perpage);

  
  useEffect(() => {
    // 👇️ scroll to top on page load
    
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    
    
  }, [pageNo]);
  

  const pageChange = ({ selected }) => {
    console.log(selected)
    setPageLoading(true)
    setPageNo(selected);
    setTimeout(() => {
      setPageLoading(false)
    }, 3000);
  };

     useEffect(async() => {
        if(token) {
          setPageLoading(true)
            const res=await axios.get(`${apilink}/api/admin/authVerify`,{
                headers: {
                  Authorization: token,
                },
              })
              // console.log(res.data)
              if(!res.data.success)
              {
                Cookies.remove('_edushark_admin_access_token');
               localStorage.removeItem('_edushark_admin_access_login');
              console.clear();
              window.location.href = '/login';
              }else{
               
                if(!res.data.userInfo?.isAdmin)
                {
                  window.location.href = '/login';
                }
      
              }
              setTimeout(() => {
                setPageLoading(false)
              }, 1500);
             
        }else{
          his.push("/login")
        }
      }, []);


     const onDelete=async(id)=>{
// console.log(id)
        const res=await axios.get(`${apilink}/api/purchesbook/deletebook/${id}`,{
            headers: {
                Authorization: token,
              },
        })
        if(res.data.success)
        {
            setBook_List(book_List.filter((v)=>v._id != id))
            alert.success(res.data.msg)
        }else{
            alert.error(res.data.msg)
        }

     }

     const getallbooks=async()=>{
      setPageLoading(true)
        const res=await axios.get(`${apilink}/api/purchesbook/getallbooks`)
        // console.log(res.data)
        if(res.data.success)
        {
            setBook_List(res.data.result)
        }else{
            alert.error(res.data.msg)
        }
        setTimeout(() => {
          setPageLoading(false)
        }, 2000);
      }

      useEffect(()=>{
        getallbooks()
      },[])

      const onSeenUpdate=async(id)=>{
        const res=await axios.get(`${apilink}/api/purchesbook/updateSeenBook/${id}`,{
          headers: {
            Authorization: token,
          },
        })
        // console.log(res.data)
        if(res.data.success)
        {
          getallbooks()
          alert.success(res.data.msg)

        }else{
          alert.error(res.data.msg)
        }
      }

      const onSearch=async(e)=>{
        e.preventDefault()
        setLoading(true)
    
        const res=await axios.get(`${apilink}/api/purchesbook/searchBook/${searchText}`)
        // console.log(res.data)
    
        if(res.data.success)
        {
         
          setTimeout(() => {
            setPopup(false)
            setSearchText("")
            setBook_List(res.data.result);
            
            setLoading(false)
          }, 1500);
        }else{
        
          setTimeout(() => {
            setPopup(false)
            setSearchText("")
            alert.error(res.data.msg)
            setLoading(false)
          }, 1500);
        }
    
       
    
    
    
        
    
      }

  return (
    <>

{
    popup &&  <div className="model_box">
    <div className="inner_model_sobig">
      <div className="cross" onClick={()=>{
        setPopup(false)
        setSearchText("")

      }}>&times;</div>
      <div>
       <div className="text-left">
        <h5>Search Your Book</h5>
       
       </div>
       <form className="my-3 searchform" onSubmit={onSearch}>
      
       <div class="input-group mb-3">
    <input type="text" class="form-control" value={searchText} onChange={(e)=>setSearchText(e.target.value)} placeholder="Search Books by name , author , publisher" required />
    <div class="input-group-append">
      <button  className={
                        loading ? 'dis btn btn-primary' : 'btn btn-primary'
                      }
                      disabled={loading} type="submit">Search</button>  
     </div>
  </div>  
        </form>
        <div className="text-center">
        {loading && (
                    <div className="text-center p-2">
                      <CircularProgress  size={40} />
                    </div>
                  )}
        </div>
      </div>
    </div>
  </div>
   }
    
    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
          <div className="container-fluid">
             <div className="dd_flex">
             <h3 className="text_custom">Books</h3>
          <div className="text-right">
        <button className='btn btn-primary ' onClick={()=>his.push("/add-purchase-book")}>Add Book</button>
        <button className="btn btn-primary ml-1" onClick={()=>{

          // setBook_List([])       
                  getallbooks()
                  // setPageNo(0)
                }}> <i className=" fa fa-refresh "></i></button>
        <button className="btn btn-primary  ml-1" onClick={()=>{
                  setPopup(true)
                }}> <i className=" fa fa-search "></i></button>
    </div>
            </div>

           {
            dataall?.length > 0 ?(
                <>
                 <div className="gallery_flex mt-5">
           {
            dataall?.map((val,ind)=>{
                return(
                    <>
                    
                    <div className="youtube_flex_item " key={ind} >
                        {
                            val.bookimage?.length > 0 ? <img src={val.bookimage[0]} className='purches_img_book cur' alt="" onClick={()=>his.push(`/edit-purchase-book/${val._id}`)} /> : <img src={`${path}/image/img.jpg`} className='purches_img_book cur' alt="" /> 
                        }
                            
                           <div className="p-2">
                     
                           
                           <NavLink to={`/edit-purchase-book/${val._id}`} className="title_a"  > <h4>{val.bookname}</h4></NavLink>
                           <p className='m-0'>Weight = &nbsp; {val.bookweight}</p>      

                          
                         <div className="text-right">
                          {
                            val.book_isActive  ?   <i onClick={()=>onSeenUpdate(val._id)} className=' mr-2 fa fa-eye text-success cur '></i> :    <i onClick={()=>onSeenUpdate(val._id)} className=' mr-2 fa fa-eye text-danger cur '></i>
                          }
                       

                         <NavLink to={`/edit-purchase-book/${val._id}`}><i className='fa fa-edit text-custom '></i></NavLink>
                         </div>
                           </div>
                            <div className="delete_btn" onClick={()=>onDelete(val._id)}>
                                <i className='fa fa-trash ' ></i>       
                            </div>

                        </div>
                    </>
                )
            })
           }
            </div>


                
                </>
            ):(
                <>

                <div className="p-5 text-center">
                    <h5>No Item Found</h5>
                </div>
                
                </>
            )
           }

<div className="row pt-4">
         <div className="col-12">
         {
          dataall.length > 0 &&  <ReactPaginate
          previousLabel={'Prev'}
          nextLabel={'Next'}
          pageCount={boxno}
          onPageChange={pageChange}
          containerClassName={'pagination'}
          // previousLinkClassName={"prevbutton"}
          // nextLinkClassName={"nextbutton"}
          // disabledClassName={"pagedisable"}
          activeClassName={'activebutton'}
        />
         }
         </div>
        </div>
          

          </div>
         
           
          </div>
        </div>
      </div>

      {
        pageloading && <>
<div className="pageloading">
<div class="loadingio-spinner-bean-eater-ghxsb80unx"><div class="ldio-xz472s6kr1">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div>
</div>


        </>
      }
    
    </>
  )
}

export default PurchesBook