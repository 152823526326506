import React, { useState } from 'react'
import './Auth.css'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { apilink, path } from '../../data/fdata';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import Cookies from 'js-cookie';

import validator from 'validator';


const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [cpassword, setCPassword] = useState('');

  const [col, setCol] = useState('');
 

  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [passwordVis, setPasswordVis] = useState("password");

  
  const his = useHistory();
  const { accesstoken} = useParams();

  const onSub = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    if(password.length <6) {

      setTimeout(() => {
        setCol("alert-warning")
        setStatus(true)
      setMsg("Password must be at least 6 characters.")
      setLoading(false);
      }, 1500);

    }
    else if(password !=cpassword ) {

      setTimeout(() => {
        setStatus(true)
      setMsg("Password not match")
      setLoading(false);
      setCol("alert-warning")
      }, 1500);
      
    }else{

      if(validator.isStrongPassword(password, {
        minLength: 6,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      }))

      {


        const res=await axios.post(`${apilink}/api/admin/resetPassword`,{
          password,
          activation_token: accesstoken
        })
        if(res.data.success)
        {
          setTimeout(() => {
            Cookies.remove('_edushark_admin_access_token');
             localStorage.removeItem('_edushark_admin_access_login');
            console.clear();
            setStatus(true)
          setMsg(res.data.msg)
          setLoading(false);
          setCol("alert-success")
          }, 1500);
    
          setTimeout(() => {
            his.push("/login")
          }, 3000);
    
        }else{
          setTimeout(() => {
            setStatus(true)
          setMsg(res.data.msg)
          setLoading(false);
          setCol("alert-warning")
          }, 1500);
          
        }


      }else{
        setTimeout(() => {
          setStatus(true)
        setMsg('Use strong password: 1 number, 1 uppercase, 1 lowercase, 1 special character')
        setLoading(false);
        setCol("alert-warning")
        }, 1500);
      }

      

    }

    
  };

  return (
    <>
    <div className="auth">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-7 col-12 mx-auto">
              <div className="card p-3">
                {status ? (
                  <>
                    <div class={`alert ${col} alert-dismissible`}>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        onClick={() => setStatus(false)}
                      >
                        &times;
                      </button>
                      {msg}
                    </div>
                  </>
                ) : null}
<img
                    src={`${path}/image/logo.png`}
                    alt=""
                    className="logoimg"
                  />
                <h5 className="text-center pb-3">Reset Password</h5>
                <br />
                <form onSubmit={onSub} className="">
                  <div class="form-group">
                    <input
                      type={passwordVis}
                      placeholder="Enter New Password"
                      class="form-control"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type={passwordVis}
                      placeholder="Confirm Password"
                      class="form-control"
                      name="cpassword"
                      value={cpassword}
                      onChange={(e) => setCPassword(e.target.value)}
                      required
                    />
                  </div>
                  {
                passwordVis =="password" ?    <p className=' fn_12 cur re_p' onClick={()=>setPasswordVis("text")}>View password</p> :    <p className=' fn_12 cur re_p' onClick={()=>setPasswordVis("password")}>Hide password</p>
               }
                 
                  <div className="text-center">
                    <button
                      type="submit"
                      className={
                        loading ? 'dis sm_btn' : 'sm_btn'
                      }
                      disabled={loading}
                    >
                      Update Now
                    </button>
                  </div>
                  {loading && (
                    <div className="text-center p-2">
                      <CircularProgress  size={35} />
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </>
  )
}

export default ResetPassword