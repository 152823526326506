import React, {  useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./Accordion.css";
import { useSpring, animated } from "react-spring";
import { CircularProgress } from '@mui/material'
import { useAlert } from "react-alert";
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { apilink, subjects } from "../../data/fdata";
import Cookies from "js-cookie";
import axios from "axios"
import { saveAs } from 'file-saver';
import { ToWords } from 'to-words';

function Accordion({ index, title, data , allApplicationsById  , paymentDataObj ,  paymentData}) {
  const token = Cookies.get('_edushark_admin_access_token');
//  const [courseDetails,setCourseDetails]=useState({})
const [pageloading, setPageLoading] = useState(false);
const toWords = new ToWords();
  const his = useHistory();
const alert=useAlert()
const [loading,setLoading] =useState(false)
  const [open, setOpen] = useState(false);
  const [popup, setPopup] = useState(false);
  const [clgPopup, setClgPopup] = useState(false);

  const [choose_college, setChoose_college] = useState("");
  const [choose_collegeList, setChoose_collegeList] = useState([]);  
  const [choose_collegeId, setChoose_collegeId] = useState("");
  const [college_Course_Price, setCollege_Course_Price] = useState("");
  const [choose_subject, setChoose_subject] = useState("");
  const [courseName, setCourseName]=useState("")
  const [applicationStatusMsg,setApplicationStatusMsg]=useState("")

  const [feeStructure,setFeeStructure]=useState({})


  const [updateStatus, setUpdateStatus] = useState("");
 const {appid}=useParams()
 
 const dataReason=[
  {
    status:"PENDING",
    statusMsg:"Something Wrong",
  },
  {
    status:"APPROVED",
    statusMsg:"Correct Data",
  },
  {
    status:"REJECTED",
    statusMsg:"Wrong Data",
  }
 ]
    

  //toggle accordion function
  let toggleHandler = (e) => {
    //switch state
    setOpen(!open);
  };
  useEffect(() => {
    if (index == 1) setOpen(!open);
  }, []);
  //conditional styling
  const styles = {
    //if open is true, change color of title
    accordionTitle: {
      color: open ? "#0082e6" : "#000000",
    },
  };
  //open animation with react spring

  const openAnimation = useSpring({
    from: { opacity: "0", maxHeight: "40px" },
    to: { opacity: "1", maxHeight: open ? "100%" : "40px" },
    config: { duration: "120" },
  });

  //rotate animation
  const iconAnimation = useSpring({
    from: {
      transform: "rotate(0deg)",
      color: "#000000",
    },
    to: {
      transform: open ? "rotate(180deg)" : "rotate(0deg)",
      color: open ? "#0082e6" : "#000000",
    },
    config: { duration: "120" },
  });


  const getCourseById=async(cid)=>{
    const res=await axios.get(`${apilink}/api/course/getCourseById/${cid}`)
    // console.log(res.data)
    if(res.data.success)
    {
     
      // setCourseDetails(res.data.result)
      setChoose_collegeList(res.data.result?.cou_colleges)
      
    }else {
      alert.error(res.data.msg);
    }

  }

  const onUpdate=async(e)=>{
    e.preventDefault()
    setLoading(true)
    const da={
      applicationStatusMsg,
      aid:data?._id      ,
      status:updateStatus,
      stu_id:data?.userID,
      ...paymentDataObj
    }

    const res=await axios.post(`${apilink}/api/application/updateApplication`,da,{
      headers: {
        Authorization: token,
      },
    })
// console.log(res.data)
    if(res.data.success)
    {
      allApplicationsById()
      setTimeout(() => {
        setLoading(false)
        alert.success(res.data.msg)
      setPopup(false)
      setUpdateStatus("")
      }, 2000);

      
    }else{
      if(res.data.msg=="Invalid Authentication.")
      {
        Cookies.remove('_edushark_admin_access_token');
           localStorage.removeItem('_edushark_admin_access_login');
          console.clear();
          window.location.href = '/login';
      }else{
        setTimeout(() => {
          setLoading(false)
          alert.error(res.data.msg)
        }, 2000);
        
      }
    }

  }

  const handelSubjectCollege = async (e , coursid) => {
    setChoose_college("")
    setChoose_collegeId("")
    setCollege_Course_Price("")

   
    
    const res = await axios.post(`${apilink}/api/course/handelSubjectCollege`, {
      text: e.target.value,
      cid: coursid,
    });
    // console.log(res.data.result)
    if (res.data.success) {
      setChoose_collegeList(res.data.result);
     

    } else {
      alert.error(res.data.msg);
    }
  };

  const onUpdateClg=async(e)=>{
    e.preventDefault()
    setLoading(true)
   let clgdata;
    if(courseName=="deled")
    {
       clgdata={
        feeStructure,
        appid,
        userID:data?.userID,
        collegeID:choose_collegeId,
        course_fees:college_Course_Price ,
       dled_form:{
        schoolinfo:data.dled_form.schoolinfo,
          choose_college,
        },
        agreementCopy:`
        <div class=" ">
        <p class="m-0 mb-3">
         I am <span class="text-primary">${data.personalinfo.applicant_name}</span>, son
         or daughter of <span class="text-primary">${data.personalinfo.father_name}</span>, I want
          to take admission in Diploma in Education under West Bengal Primary Board of Education
        </p>
        
        <p class="m-0 mb-3 ">
          
          
          I have chosen
          <span class="text-primary">${choose_college}</span> as my
          college in
          <span class="text-primary">${data.session_year}</span> academic year.
        
       
          I Understand that Total course fee for Diploma in Education is
          <b class="text-primary"> Rs- ${college_Course_Price} (${ college_Course_Price && toWords.convert(Number(college_Course_Price)) })  </b> . This
          Course Fee Includes only Tuition Fee of a Diploma in Education
          Student. This Course Fee is Excluding GST and Transaction Charge
          or Payment Gateway fee
          </p>
        <p class="m-0 mb-3">
        
          I also understand that This Course Fee is divided into five
          parts for the Convenience of the students.This 5 parts are
          namely - <span class="text-primary">(1) Admission Fee (2) 1st Semester Fee (3) 2nd Semester
          Fee (4) 3rd Semester Fee (5) 4th Semester Fee.</span> I will pay the
          fee for each Semester before the Semester Examination.
        </p>
        <p class="">
        
        <span class="text-primary"> I am Also Aware of That I have to pay</span> - one time Registration
          Fee,Two times Examination Fee (excluding supplementary
          examination fee),Fee for Dress, Fee for Identity card. <br /> <span class="text-primary"> Others
          Fees that I may have to pay </span> -Excursional Tour Charge (Optional),
          Observation Fee (Optional), hostel fee (optional), Library
          Charge (optional)
        </p>
      
        <p>
        
          I am <span class="text-primary">${data.personalinfo.applicant_name}</span> Son
          or Daughter of
          <span class="text-primary">${data.personalinfo.father_name}</span> residing at
          <span class="text-primary">${data.addressinfo.paddress},${data.addressinfo.ppo},${data.addressinfo.pps},${data.addressinfo.pdistrict},${data.addressinfo.pstate},${data.addressinfo.ppin}</span> do hereby
          solemnly affirm and declare as follows:
        </p>
        <ul class="ml-5">
          <li>
            I accept the fee structure mentioned above and undertake to
            pay all fees on time. Team Edushark and West Bengal Primary Board of Education may take necessary action against me if I
            fail to pay the fee on time for any reason.
          </li>
          <li>
          I am ${data.personalinfo.applicant_name}, if for any reason I do not continue the Diploma in Education course 
          then I will have to pay 75% of my total course fee to Edushark. 
          Otherwise Edushark authorities may take legal action against me. 
          Any legal complications shall be subject to Murshidabad jurisdiction.

            </li>
          <li>
            
            I hereby declare that if I discontinue my education then
            Edushark shall not be liable in any way to refund me the fees
            paid by me till the time of discontinuance of my education.
          </li>
          <li>
            I also declare that all the statements made in the application
            form are true and correct to the best of my knowledge and
            belief, if any fraud and false details are found in my
            statement and my uploaded documents, the concerned authority
            reserves the right to cancel my studentship along with my
            application.
          </li>
        </ul>
      
         
        </div>
        
        `
      }
      
    }else if(courseName=="bed")
    {
      clgdata={
        feeStructure,
        appid,
        userID:data?.userID,
        collegeID:choose_collegeId,
        course_fees:college_Course_Price ,
        bed_form:{
          schoolinfo:data.bed_form.schoolinfo,
          collegeinfo:data.bed_form.collegeinfo,
          choose_subject,
          choose_college,
        },
       agreementCopy:`
       <div class="">
          <p class="m-0 mb-3">
           I am <span class="text-primary">${data.personalinfo.applicant_name}</span>, son
           or daughter of <span class="text-primary">${data.personalinfo.father_name}</span>, I want
            to take admission in Bachelor of education under Babasaheb
            Ambedkar University (Erstwhile The West Bengal University of
            Teachers' Training, Education Planning and Administration).
          </p>
          
          <p class="m-0 mb-3 ">
            
           
            I have chosen
            <span class="text-primary">${choose_college}</span> as my
            college and
            <span class="text-primary"> ${choose_subject}</span> for
            Bachelor of Education(B.Ed) in
            <span class="text-primary">${data.session_year}</span> academic year.
          
         
            I Understand that Total course fee for Bachelor of Education is
            <b class="text-primary"> Rs- ${college_Course_Price} (${ college_Course_Price && toWords.convert(Number(college_Course_Price)) }) </b> . This
            Course Fee Includes only Tuition Fee of a Bachelor of Education
            Student. This Course Fee is Excluding GST and Transaction Charge
            or Payment Gateway fee
            </p>
          <p class="m-0 mb-3">
      
            I also understand that This Course Fee is divided into five
            parts for the Convenience of the students.This 5 parts are
            namely - <span class="text-primary">(1) Admission Fee (2) 1st Semester Fee (3) 2nd Semester
            Fee (4) 3rd Semester Fee (5) 4th Semester Fee.</span> I will pay the
            fee for each Semester before the Semester Examination.
          </p>
          <p class="">
          
          <span class="text-primary"> I am Also Aware of That I have to pay</span> - one time Registration
            Fee,Four times Examination Fee (excluding supplementary
            examination fee),Fee for Dress, Fee for Identity card. <span class="text-primary"> Others
            Fees that I may have to pay </span> -Excursional Tour Charge (Optional),
            Observation Fee (Optional), hostel fee (optional), Library
            Charge (optional)
          </p>
      
          <p>
          
            I am <span class="text-primary">${data.personalinfo.applicant_name}</span> Son
            or Daughter of
            <span class="text-primary">${data.personalinfo.father_name}</span> residing at
            <span class="text-primary">${data.addressinfo.paddress},${data.addressinfo.ppo},${data.addressinfo.pps},${data.addressinfo.pdistrict},${data.addressinfo.pstate},${data.addressinfo.ppin}</span> do hereby
            solemnly affirm and declare as follows:
          </p>
          <ul class="pl-5">
            <li>
              I accept the fee structure mentioned above and undertake to
              pay all fees on time. Team Edushark and Baba Saheb Ambedkar
              Education University may take necessary action against me if I
              fail to pay the fee on time for any reason.
            </li>
            <li>
          I am ${data.personalinfo.applicant_name}, if for any reason I do not continue the Bachelor of Education(B.Ed) course 
          then I will have to pay 75% of my total course fee to Edushark. 
          Otherwise Edushark authorities may take legal action against me. 
          Any legal complications shall be subject to Murshidabad jurisdiction.

            </li>
            <li>
              
              I hereby declare that if I discontinue my education then
              Edushark shall not be liable in any way to refund me the fees
              paid by me till the time of discontinuance of my education.
            </li>
            <li>
              I also declare that all the statements made in the application
              form are true and correct to the best of my knowledge and
              belief, if any fraud and false details are found in my
              statement and my uploaded documents, the concerned authority
              reserves the right to cancel my studentship along with my
              application.
            </li>
          </ul>
         
          </div>
       
       `
      }
    }
    else{
       clgdata={
        feeStructure,
        appid,
        userID:data?.userID,
        collegeID:choose_collegeId,
        course_fees:college_Course_Price ,
        dpharm_form:{
          schoolinfo:data.dpharm_form.schoolinfo,
          choose_college,
        },
        agreementCopy:`

        <div class=" ">
        <p class="m-0 mb-3">
         I am <span class="text-primary">${data.personalinfo.applicant_name}</span>, son
         or daughter of <span class="text-primary">${data.personalinfo.father_name}</span>, I want
          to take admission in Diploma in Pharmacy under Health & Family Welfare Department, Government of West Bengal
        </p>
        
        <p class="m-0 mb-3 ">
          
          
          I have chosen
          <span class="text-primary">${choose_college}</span> as my
          college in
          <span class="text-primary">${data.session_year}</span> academic year.
        
       
          I Understand that Total course fee for Diploma in Pharmacy is
          <b class="text-primary"> Rs-${college_Course_Price} (${ college_Course_Price && toWords.convert(Number(college_Course_Price)) }) </b> . This
          Course Fee Includes only Tuition Fee of a Diploma in Pharmacy
          Student. This Course Fee is Excluding GST and Transaction Charge
          or Payment Gateway fee
          </p>
        <p class="m-0 mb-3">
        
          I also understand that This Course Fee is divided into five
          parts for the Convenience of the students.This 5 parts are
          namely - <span class="text-primary">(1) Admission Fee (2) 1st Semester Fee (3) 2nd Semester
          Fee (4) 3rd Semester Fee (5) 4th Semester Fee.</span> I will pay the
          fee for each Semester before the Semester Examination.
        </p>
        <p class="">
        
        <span class="text-primary"> I am Also Aware of That I have to pay</span> - one time Registration
          Fee,Four times Examination Fee (excluding supplementary
          examination fee),Fee for Dress, Fee for Identity card. <br /> <span class="text-primary"> Others
          Fees that I may have to pay </span> -Excursional Tour Charge (Optional),
          Observation Fee (Optional), hostel fee (optional), Library
          Charge (optional)
        </p>
      
        <p>
        
        I am <span class="text-primary">${data.personalinfo.applicant_name}</span> Son
        or Daughter of
        <span class="text-primary">${data.personalinfo.father_name}</span> residing at
        <span class="text-primary">${data.addressinfo.paddress},${data.addressinfo.ppo},${data.addressinfo.pps},${data.addressinfo.pdistrict},${data.addressinfo.pstate},${data.addressinfo.ppin}</span> do hereby
        solemnly affirm and declare as follows:
        </p>
        <ul class="ml-5">
          <li>
            I accept the fee structure mentioned above and undertake to
            pay all fees on time. Team Edushark and Health & Family Welfare Department, Government of West Bengal may take necessary action against me if I
            fail to pay the fee on time for any reason.
          </li>
           <li>
          I am ${data.personalinfo.applicant_name}, if for any reason I do not continue the Diploma in Pharmacy course 
          then I will have to pay 75% of my total course fee to Edushark. 
          Otherwise Edushark authorities may take legal action against me. 
          Any legal complications shall be subject to Murshidabad jurisdiction.

            </li>
          <li>
            
            I hereby declare that if I discontinue my education then
            Edushark shall not be liable in any way to refund me the fees
            paid by me till the time of discontinuance of my education.
          </li>
          <li>
            I also declare that all the statements made in the application
            form are true and correct to the best of my knowledge and
            belief, if any fraud and false details are found in my
            statement and my uploaded documents, the concerned authority
            reserves the right to cancel my studentship along with my
            application.
          </li>
        </ul>
         
        </div>
        
        `
      }
    }

    // console.log(clgdata)

    const res=await axios.post(`${apilink}/api/application/updateApplicationCollege`,clgdata,{
      headers: {
        Authorization: token,
      },
    })

    // console.log(res.data)

    if(res.data.success)
    {
      allApplicationsById()
      setTimeout(() => {
        alert.success(res.data.msg)
      setLoading(false)
      setClgPopup(false)
      setChoose_college("")
      setChoose_collegeId("")
      setCollege_Course_Price("")
      setChoose_subject("")
      // setChoose_collegeList([])
      }, 2000);
    }else{
      setTimeout(() => {
        alert.error(res.data.msg)
      setLoading(false)
      setClgPopup(false)
      setChoose_college("")
      setChoose_collegeId("")
      setCollege_Course_Price("")
      setChoose_subject("")
      // setChoose_collegeList([])
      }, 2000);
    }



    
  }

  useEffect(()=>{

    if(choose_college)
    {
     let ar= paymentData.filter((v)=>v.collageName ==  choose_college     ) 
     setFeeStructure({
      collegeId:ar[0].collegeId,
      collageName:ar[0].collageName,
      collegefees:ar[0].collegefees

     })
    }

    
  },[choose_college])

  useEffect(()=>{
    if(data?.course_name)
    {
      
        setCourseName(data?.course_name?.toLowerCase())
      
    }
    if(data?.course_name != "BED" && data?.courseID)
    {
      getCourseById(data?.courseID)
    }
  },[data])



  const applicationpdf=async(aid)=>{
    setPageLoading(true)
    const res=await axios.post(`${apilink}/api/pdf/application-pdf`,{
      aid
    })
   
    if(res.data.success)
    {
     alert.success(res.data.msg)
     const ress=await axios.get(`${apilink}/api/pdf/fetch-pdf/${aid}`,{ responseType: 'blob' })
         if(ress)
         {
             const pdfBlob = new Blob([ress.data], { type: 'application/pdf' });
  
               saveAs(pdfBlob, `application_pdf_${aid}.pdf`);
               const re=await axios.get(`${apilink}/api/pdf/delete-pdf/${aid}`)
               if(!re.data.success)
               {
                 his.push("something-wrong")
               }
         }
  
    }else{
     alert.error("Something went wrong!!")
    //  res.data.msg
    }
    setPageLoading(false)
  }


  const agreementpdf=async(aid)=>{
    setPageLoading(true)
    const res=await axios.post(`${apilink}/api/pdf/agreement-pdf`,{
      aid
    })
   
    if(res.data.success)
    {
     alert.success(res.data.msg)
     const ress=await axios.get(`${apilink}/api/pdf/fetch-pdf/${aid}`,{ responseType: 'blob' })
         if(ress)
         {
             const pdfBlob = new Blob([ress.data], { type: 'application/pdf' });
  
               saveAs(pdfBlob, `agreement_pdf_${aid}.pdf`);
               const re=await axios.get(`${apilink}/api/pdf/delete-pdf/${aid}`)
               if(!re.data.success)
               {
                 his.push("something-wrong")
               }
         }
  
    }else{
    //  alert.error(res.data.msg)
    alert.error("Something went wrong!!")

    }
    setPageLoading(false)
  }


 

  return (
    <>
   {
    popup &&  <div className="model_box">
    <div className="inner_model">
      <div className="cross" onClick={()=>setPopup(false)}>&times;</div>
      <div>
       <div className="text-center">
        <h5>Update Application</h5>
        <form className="my-4" onSubmit={onUpdate}>
        <div className="form-group ">
                   
                       <select
                          class="form-control form_h"
                          value={updateStatus}
                          onChange={(e) => {
                            setUpdateStatus(e.target.value)
                           let ar= dataReason.filter((v)=>v.status==e.target.value)
                           ar.length && setApplicationStatusMsg(ar[0].statusMsg)
                            
                          }}
                          required
                        >
                          <option selected hidden value="">
                            --Choose Status--
                          </option>
                          <option value="PENDING">Pending</option>
                          <option value="APPROVED">Approved</option>
                          <option value="REJECTED">Rejected</option>

                       
                        </select>
                       
                     </div>
                     <div class="form-group">
                          <p className="text-left mb-0">Status update reason:</p>
                          <input
                            type="text"
                            placeholder="Reason"
                            class="form-control"
                            name="applicationStatusMsg"
                            required
                            value={applicationStatusMsg}
                            onChange={(e) =>
                              setApplicationStatusMsg(e.target.value)
                            }
                          />
                        </div>
                     <div className="text-center">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Submit
                      </button>
                      {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} />
                      </div>
                    )}
                    </div>
        </form>
       </div>
      </div>
    </div>
  </div>
   }
   {
    clgPopup && <div className="model_box">
    <div className="inner_model">
      <div className="cross" onClick={()=>{
        setClgPopup(false)
        setChoose_college("")
        setChoose_collegeId("")
        setCollege_Course_Price("")
        setChoose_subject("")
        // setChoose_collegeList([])
      }}>&times;</div>
      <div>
       <div className="text-center">
        <h5>Update Application</h5>
        <form className="my-4 text-left" onSubmit={onUpdateClg}>
          {
            data?.course_name == "BED" && (
              <>
              
              <div className="form-group ">
        <label>Preferred Subject For B.Ed</label>
             <select
                            class="form-control"
                            value={choose_subject}
                            onChange={(e) => {
                              setChoose_subject(e.target.value);
                              handelSubjectCollege(e , data?.courseID);
                            }}
                            required
                          >
                            <option value="" selected hidden>
                              Preference for School Subject for B.Ed
                            </option>
                            {subjects?.map((val) => {
                              return <option value={val}>{val}</option>;
                            })}
                          </select>
                       
                     </div>
              </>
            )
          }
    

                     <div className="form-group ">
        <label>Choose College</label>
        <select
                            class="form-control"
                            value={choose_college}
                        
                            onChange={(e) => {
                             
                              setChoose_college(e.target.value)                            
    let ar=choose_collegeList.filter((v)=>v.collageName  == e.target.value);
    setChoose_collegeId(ar[0].collegeId)
    setCollege_Course_Price(ar[0].totalprice)
                            }}
                            required
                          >
                            <option value="" selected hidden>
                              Preference for College for B.Ed
                            </option>
                            {choose_collegeList?.map((v, ind) => {
                              return (
                                <>
                                  <option key={ind} value={v.collageName}>
                                    {v.collageName}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                       
                     </div>

                     <div className="text-center">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Submit
                      </button>
                      {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} />
                      </div>
                    )}
                    </div>
        </form>
       </div>
      </div>
    </div>
  </div>
   }
      <animated.div className="accordion__item" style={openAnimation}>
        <div className="accordion__header" onClick={toggleHandler}>
          <h4 style={styles.accordionTitle}>{title}</h4>
          <animated.i style={iconAnimation}>
            <ExpandMoreIcon />
          </animated.i>
        </div>
        <div className="accordion__content">
          {index == 1 && (
            <>
            <div className="row mb-4">
              <div className="col-12">
              <h4>
                    <span>Application ID:</span>
                    {data?.AppID}
                  </h4>
              </div>
            </div>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Session:</span>
                    {data?.session_year}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Course:</span>
                    {data?.course_name}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>College:</span>
                    {data?.bed_form?.choose_college.length > 0 &&
                      data?.bed_form?.choose_college}

                    {data?.dled_form?.choose_college.length > 0 &&
                      data?.dled_form?.choose_college}

                    {data?.dpharm_form?.choose_college.length > 0 &&
                      data?.dpharm_form?.choose_college}
                  </h4>
                </div>
                {
                  data?.bed_form?.choose_subject.length > 0  &&  <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>College Subject:</span>
                    {
                      data?.bed_form?.choose_subject}

                   
                  </h4>
                </div>
                }
                
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Course_Fees:</span>
                    {data?.course_fees}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span> ApplicationStatus:</span>
                    {data?.ApplicationStatus == "PENDING" && (
                      <small className="badge badge-warning">Pending</small>
                    )}
                    {data?.ApplicationStatus == "APPROVED" && (
                      <small className="badge badge-success">Approved</small>
                    )}
                    {data?.ApplicationStatus == "REJECTED" && (
                      <small className="badge badge-danger">Rejected</small>
                    )}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Reffered_by:</span>
                    {data?.reffered_by}
                  </h4>
                </div>
                <div className="col-12 mb-4 ">
                  {
                    !data?.isReverify && (
                      <>

            {
                    data.paymentStatus &&(
                      <>
                       <button className="sml_btn mr-1 bg-primary" onClick={()=>setPopup(true)}>Update Status</button>
                    <button className="sml_btn ml-1 bg-primary" onClick={()=>{
                      setClgPopup(true)
                      
                    }}>Update College</button>
                      </>
                    )
                  }
                      
                      </>
                    )
                  }
                  
                
                   
                </div>
                
                <div className="col-12 mb-2">
                <button onClick={()=>applicationpdf(data._id)} className='sml_btn mr-1 bg-primary'><i className='fa fa-download'></i> Application</button>
                   <button  onClick={()=>agreementpdf(data._id)} className='sml_btn ml-1 bg-primary'><i className='fa fa-download'></i> Agreement</button>
                </div>
              </div>
            </>
          )}
          {index == 2 && (
            <>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Name:</span>
                    {data?.personalinfo?.applicant_name}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Have Student Id:</span>
                    {data?.have_bss_id}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2">
                  <h4>
                    <span>Student Id:</span>
                    {data?.bss_id}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Father_name:</span>
                    {data?.personalinfo?.father_name}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Mother_name:</span>
                    {data?.personalinfo?.mother_name}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Guardian_name:</span>
                    {data?.personalinfo?.guardian_name}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Relation_with_guardian:</span>
                    {data?.personalinfo?.relation_with_guardian}
                  </h4>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Gender:</span>
                    {data?.personalinfo?.gender}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Category:</span>
                    {data?.personalinfo?.category}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>BloodGp:</span>
                    {data?.personalinfo?.bloodGp}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Aadhar_no:</span>
                    {data?.personalinfo?.aadhar_no}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Applicant_dob:</span>
                    {new Date(
                      data?.personalinfo?.applicant_dob
                    ).toLocaleDateString('en-GB')}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>languages:</span>
                    {data?.personalinfo?.languages}
                  </h4>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Nationality:</span>
                    {data?.personalinfo?.nationality}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Religion:</span>
                    {data?.personalinfo?.religion}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Marital_status:</span>
                    {data?.personalinfo?.marital_status}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Bpl:</span>
                    {data?.personalinfo?.bpl}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Specially_abled:</span>
                    {data?.personalinfo?.specially_abled}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Percentage_disable:</span>
                    {data?.personalinfo?.percentage_disable}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Type_diable:</span>
                    {data?.personalinfo?.type_diable}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Income:</span>
                    {data?.personalinfo?.income}
                  </h4>
                </div>
              </div>
            </>
          )}

          {index == 3 && (
            <>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Applicant_email:</span>
                    {data?.contactinfo?.applicant_email}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Applicant_phone:</span>
                    {data?.contactinfo?.applicant_phone}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Applicant_whatsappno:</span>
                    {data?.contactinfo?.applicant_whatsappno}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Guardian_phone:</span>
                    {data?.contactinfo?.guardian_phone}
                  </h4>
                </div>
              </div>
              <p>
                <b>Current Address</b>
              </p>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Address:</span>
                    {data?.addressinfo?.caddress}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Block:</span>
                    {data?.addressinfo?.cblock}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Block_name:</span>
                    {data?.addressinfo?.cblockname}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>State:</span>
                    {data?.addressinfo?.cstate}
                  </h4>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>district:</span>
                    {data?.addressinfo?.cdistrict}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>PoliceStation:</span>
                    {data?.addressinfo?.cps}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>P.O:</span>
                    {data?.addressinfo?.cpo}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Pin:</span>
                    {data?.addressinfo?.cpin}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Country:</span>
                    {data?.addressinfo?.ccountry}
                  </h4>
                </div>
              </div>
              <p>
                <b>Parmanent Address</b>
              </p>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Address:</span>
                    {data?.addressinfo?.paddress}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Block:</span>
                    {data?.addressinfo?.pblock}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Block_name:</span>
                    {data?.addressinfo?.pblockname}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>State:</span>
                    {data?.addressinfo?.pstate}
                  </h4>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>district:</span>
                    {data?.addressinfo?.pdistrict}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>PoliceStation:</span>
                    {data?.addressinfo?.pps}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>P.O:</span>
                    {data?.addressinfo?.ppo}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Pin:</span>
                    {data?.addressinfo?.ppin}
                  </h4>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                  <h4>
                    <span>Country:</span>
                    {data?.addressinfo?.pcountry}
                  </h4>
                </div>
              </div>
            </>
          )}

          {index == 4 && (
            <>
              {data?.bed_form?.choose_college.length > 0 && (
                <>
                  <p>
                    <b>School Details</b>
                  </p>
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>LastUniversity:</span>
                        {data?.bed_form?.schoolinfo?.lastUniversity}
                      </h4>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>RegNo_last_University:</span>
                        {data?.bed_form?.schoolinfo?.regNo_lastu}
                      </h4>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_board:</span>
                        {data?.bed_form?.schoolinfo?.mboard}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_year:</span>
                        {data?.bed_form?.schoolinfo?.myear}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_full_marks:</span>
                        {data?.bed_form?.schoolinfo?.mfull_marks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_marks:</span>
                        {data?.bed_form?.schoolinfo?.mmarks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_percentage_marks:</span>
                        {data?.bed_form?.schoolinfo?.mpercentage_marks}
                      </h4>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_board:</span>
                        {data?.bed_form?.schoolinfo?.hboard}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_year:</span>
                        {data?.bed_form?.schoolinfo?.hyear}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_full_marks:</span>
                        {data?.bed_form?.schoolinfo?.hfull_marks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_marks:</span>
                        {data?.bed_form?.schoolinfo?.hmarks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_percentage_marks:</span>
                        {data?.bed_form?.schoolinfo?.hpercentage_marks}
                      </h4>
                    </div>
                  </div>
                  <p>
                    <b>College Details</b>
                  </p>
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Status_ug:</span>
                        {data?.bed_form?.collegeinfo?.status_ug}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Type_ug:</span>
                        {data?.bed_form?.collegeinfo?.type_ug}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>University:</span>
                        {data?.bed_form?.collegeinfo?.university}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>U_year:</span>
                        {data?.bed_form?.collegeinfo?.uyear}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>U_full_marks:</span>
                        {data?.bed_form?.collegeinfo?.ufull_marks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>U_marks:</span>
                        {data?.bed_form?.collegeinfo?.umarks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>U_percentage_marks:</span>
                        {data?.bed_form?.collegeinfo?.upercentage_marks}
                      </h4>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Have_master:</span>
                        {data?.bed_form?.collegeinfo?.have_master}
                      </h4>
                    </div>
                    {data?.bed_form?.collegeinfo?.have_master == "YES" && (
                      <>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                          <h4>
                            <span>Status_pg:</span>
                            {data?.bed_form?.collegeinfo?.status_pg}
                          </h4>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                          <h4>
                            <span>Type_pg:</span>
                            {data?.bed_form?.collegeinfo?.type_pg}
                          </h4>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                          <h4>
                            <span>P_university:</span>
                            {data?.bed_form?.collegeinfo?.puniversity}
                          </h4>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                          <h4>
                            <span>P_year:</span>
                            {data?.bed_form?.collegeinfo?.pyear}
                          </h4>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                          <h4>
                            <span>P_full_marks:</span>
                            {data?.bed_form?.collegeinfo?.pfull_marks}
                          </h4>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                          <h4>
                            <span>P_marks:</span>
                            {data?.bed_form?.collegeinfo?.pmarks}
                          </h4>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                          <h4>
                            <span>P_percentage_marks:</span>
                            {data?.bed_form?.collegeinfo?.ppercentage_marks}
                          </h4>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
              {data?.dled_form?.choose_college.length > 0 && (
                <>
                  <p>
                    <b>School Details</b>
                  </p>
                  <div className="row">
                    

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_board:</span>
                        {data?.dled_form?.schoolinfo?.mboard}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_year:</span>
                        {data?.dled_form?.schoolinfo?.myear}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_full_marks:</span>
                        {data?.dled_form?.schoolinfo?.mfull_marks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_marks:</span>
                        {data?.dled_form?.schoolinfo?.mmarks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_percentage_marks:</span>
                        {data?.dled_form?.schoolinfo?.mpercentage_marks}
                      </h4>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_institute:</span>
                        {data?.dled_form?.schoolinfo?.hinstitute}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_board:</span>
                        {data?.dled_form?.schoolinfo?.hboard}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_year:</span>
                        {data?.dled_form?.schoolinfo?.hyear}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_full_marks:</span>
                        {data?.dled_form?.schoolinfo?.hfull_marks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_marks:</span>
                        {data?.dled_form?.schoolinfo?.hmarks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_percentage_marks:</span>
                        {data?.dled_form?.schoolinfo?.hpercentage_marks}
                      </h4>
                    </div>
                  

                                   


                  </div>
                  
                  <hr />
                  <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>First_lang:</span>
                        {data?.dled_form?.schoolinfo?.flang}
                      </h4>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>First_lang_full_marks:</span>
                        {data?.dled_form?.schoolinfo?.flang_full}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>First_lang_marks:</span>
                        {data?.dled_form?.schoolinfo?.flang_marks}
                      </h4>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Second_lang:</span>
                        {data?.dled_form?.schoolinfo?.slang}
                      </h4>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Second_lang_full_marks:</span>
                        {data?.dled_form?.schoolinfo?.slang_full}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Second_lang_marks:</span>
                        {data?.dled_form?.schoolinfo?.slang_marks}
                      </h4>
                    </div>



                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Sub1_marks:</span>
                        {data?.dled_form?.schoolinfo?.sub1_marks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Sub2_marks:</span>
                        {data?.dled_form?.schoolinfo?.sub2_marks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Sub3_marks:</span>
                        {data?.dled_form?.schoolinfo?.sub3_marks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Sub4_marks:</span>
                        {data?.dled_form?.schoolinfo?.sub4_marks}
                      </h4>
                    </div>

                  </div>


                  
                </>
              )}
              {data?.dpharm_form?.choose_college.length > 0 && (
                <>
                  <p>
                    <b>School Details</b>
                  </p>
                  <div className="row">
                 

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_board:</span>
                        {data?.dpharm_form?.schoolinfo?.mboard}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_year:</span>
                        {data?.dpharm_form?.schoolinfo?.myear}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_full_marks:</span>
                        {data?.dpharm_form?.schoolinfo?.mfull_marks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_marks:</span>
                        {data?.dpharm_form?.schoolinfo?.mmarks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>MP_percentage_marks:</span>
                        {data?.dpharm_form?.schoolinfo?.mpercentage_marks}
                      </h4>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_institute:</span>
                        {data?.dpharm_form?.schoolinfo?.hinstitute}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_board:</span>
                        {data?.dpharm_form?.schoolinfo?.hboard}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_year:</span>
                        {data?.dpharm_form?.schoolinfo?.hyear}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_full_marks:</span>
                        {data?.dpharm_form?.schoolinfo?.hfull_marks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_marks:</span>
                        {data?.dpharm_form?.schoolinfo?.hmarks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>HS_percentage_marks:</span>
                        {data?.dpharm_form?.schoolinfo?.hpercentage_marks}
                      </h4>
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Physics Marks:</span>
                        {data?.dpharm_form?.schoolinfo?.phy_marks}
                      </h4>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Chemistry Marks:</span>
                        {data?.dpharm_form?.schoolinfo?.che_marks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>High_marks_sub:</span>
                        {data?.dpharm_form?.schoolinfo?.high_marks_sub}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>High_marks:</span>
                        {data?.dpharm_form?.schoolinfo?.high_marks}
                      </h4>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Pass_CUET:</span>
                        {data?.dpharm_form?.schoolinfo?.pass_cuet}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>CUET_marks:</span>
                        {data?.dpharm_form?.schoolinfo?.cuet_marks}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>CUET_year:</span>
                        {data?.dpharm_form?.schoolinfo?.cuet_year}
                      </h4>
                    </div>
                   
                    
                  </div>

                  


                  
                </>
              )}
            </>
          )}
          {
            index == 5 && (
              <>
              
              <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <a target="_blank" href={data?.documentinfo?.profileImg}>Profile Photo <i className="fa fa-external-link"></i> </a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <a target="_blank" href={data?.documentinfo?.signatureImg}>Signature Photo <i className="fa fa-external-link"></i> </a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <a target="_blank" href={data?.documentinfo?.admit10thImg}>10th Admin Card <i className="fa fa-external-link"></i> </a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <a target="_blank" href={data?.documentinfo?.result10thImg}>10th Result  <i className="fa fa-external-link"></i> </a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <a target="_blank" href={data?.documentinfo?.result12thImg}>12th Result <i className="fa fa-external-link"></i> </a>
                    </div>
{
  data?.documentinfo?.resultugImg && <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
  <a target="_blank" href={data?.documentinfo?.resultugImg}>Under Graduation Result <i className="fa fa-external-link"></i> </a>
</div>
}

{
  data?.documentinfo?.resultpgImg && <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
  <a target="_blank" href={data?.documentinfo?.resultpgImg}>Post Graduation Result <i className="fa fa-external-link"></i> </a>
</div>
}

<div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <a target="_blank" href={data?.documentinfo?.resultAadharImg}>Aadhar Card <i className="fa fa-external-link"></i> </a>
                    </div>


                    {
  data?.documentinfo?.resultCastImg && <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
  <a target="_blank" href={data?.documentinfo?.resultCastImg}>Cast Certificate <i className="fa fa-external-link"></i> </a>
</div>
}
{
  data?.documentinfo?.otherImg && <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
  <a target="_blank" href={data?.documentinfo?.otherImg}>Other Documents <i className="fa fa-external-link"></i> </a>
</div>
}
{
  data?.documentinfo?.disableImg && <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
  <a target="_blank" href={data?.documentinfo?.disableImg}>Disable Certificate <i className="fa fa-external-link"></i> </a>
</div>
}

{
  data?.documentinfo?.fitnessImg && <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
  <a target="_blank" href={data?.documentinfo?.fitnessImg}>Fitness Certificate <i className="fa fa-external-link"></i> </a>
</div>
}

{
  data?.documentinfo?.residenceImg && <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
  <a target="_blank" href={data?.documentinfo?.residenceImg}>Residence Certificate <i className="fa fa-external-link"></i> </a>
</div>
}

                    </div>
              </>
            )
          }
          {
            index == 6 && (
              <>
              <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Payment Id:</span>
                        {data?.paymentId}
                      </h4>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Payment Amount:</span>
                        {data?.paymentAmount}
                      </h4>
                    </div>
                   
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-2 ">
                      <h4>
                        <span>Payment Status:</span>
                        {data?.paymentStatus ? "PAID" : "UNPAID"}
                      </h4>
                    </div>
                    </div>
              </>
            )
          }
        </div>
      </animated.div>


      {
        pageloading && <>
<div className="pageloading">
<div class="loadingio-spinner-bean-eater-ghxsb80unx"><div class="ldio-xz472s6kr1">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div>
</div>


        </>
      }
    </>
  );
}

export default Accordion;
