import React, { useEffect, useState } from 'react'
import Avatar from 'react-avatar';
import { NavLink  , useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import ReactPaginate from 'react-paginate';


const StuList = ({applicationList}) => {

  const [myData, setMyData] = useState(applicationList.slice(0,15));
  const [pageNo, setPageNo] = useState(0);
  const [filter, setFilter] = useState("all");
  const his = useHistory();
  // console.log(myData)

  const perpage = 5;
  const pagevisit = pageNo * perpage;

  const dataall = myData.slice(pagevisit, pagevisit + perpage);
  const boxno = Math.ceil(myData.length / perpage);

  const pageChange = ({ selected }) => {
    setPageNo(selected);
  };

  // console.log(dataall)

  useEffect(()=>{

    if(filter=="all"){
      
      setMyData(applicationList.slice(0,15));
    } else{
      setMyData(applicationList.filter((v)=>v.ApplicationStatus== filter));
    }

  },[filter])

  return (
    <>
    
   
                <div className="card p-2">
                  <div className="dd_flex">
                  <h5 className='text-custom'>Recent Students</h5>
                  <div className='dd_flex'>
            <div class="form-group">
              <select class="form-control filterform" value={filter} onChange={(e)=>setFilter(e.target.value) }>
               
                <option value="all">All</option>
                <option value="APPROVED">Approved</option>
                <option value="REJECTED">Rejected</option>
                <option value="PENDING">Pending</option>
              </select>
            </div>
           <NavLink to="/student" className="textm">
           View All
           </NavLink>
          </div>
                  </div>
                  <div class="table-responsive listmy ">
          <table class="table table-borderless dash">
            <thead>
              <tr>
                <th className="w_20">Name</th>
                <th className="w_20">Number</th>
                <th className="w_20">Course</th>

                <th className="w_60">College</th>
               
                <th className="sm_w">Status</th>
                <th className='sm_w'>Operation</th>
              </tr>
            </thead>
            <tbody className=''>
             {
              dataall.length > 0 ?(
                <>
                 {
              dataall?.map((val,ind)=>{
                  return(
                    <>
                    <tr>
                <td onClick={()=>his.push(`/student/${val._id}`)} className='card_flex cur'><Avatar name={val.personalinfo?.applicant_name} size="35" round={true}  /> {val.personalinfo?.applicant_name} </td>
                <td><a href={`tel:${val.contactinfo?.applicant_phone}`}>{val.contactinfo?.applicant_phone}</a></td>
               <td>{val.course_name}</td>
                {
                  val.bed_form?.choose_college.length > 0 &&  <td>{val.bed_form?.choose_college}</td>

                }
                {
                  val.dled_form?.choose_college.length > 0 &&  <td>{val.dled_form?.choose_college}</td>

                }
                {
                  val.dpharm_form?.choose_college.length > 0 &&  <td>{val.dpharm_form?.choose_college}</td>

                }
                

                

                <th>
                  
                  
                  {val.ApplicationStatus=="PENDING" &&  <span className='badge badge-warning'>Pending</span>}
                  {val.ApplicationStatus=="APPROVED" &&  <span className='badge badge-success'>Approved</span>}
                  {val.ApplicationStatus=="REJECTED" &&  <span className='badge badge-danger'>Rejected</span>}

                   </th>
                <th><NavLink to={`/student/${val._id}`} class="sm_btn">View</NavLink></th>
              </tr>
                    </>
                  )
                })
              }
                </>
              ):(
                <>
                <div className="text-center p-3">
                  <h5>No Items Found</h5>
                </div>
                </>
              )
             }
             
            </tbody>
          </table>
        </div>

        <div className="row pt-4">
         <div className="col-12">
         {
          dataall.length > 0 &&  <ReactPaginate
          previousLabel={'Prev'}
          nextLabel={'Next'}
          pageCount={boxno}
          onPageChange={pageChange}
          containerClassName={'pagination'}
          // previousLinkClassName={"prevbutton"}
          // nextLinkClassName={"nextbutton"}
          // disabledClassName={"pagedisable"}
          activeClassName={'activebutton'}
        />
         }
         </div>
        </div>

                </div>

              
    </>
  )
}

export default StuList