import React, { useEffect, useState } from 'react'
import './Student.css'
import SideBar from '../../component/sidebar/SideBar'
import Accordion from '../../component/accord/Accordion';
import Cookies from 'js-cookie';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { apilink } from '../../data/fdata';
import axios from 'axios';
import { useSpring, animated } from "react-spring";
import { useAlert } from 'react-alert';


const StudentDetails = () => {
 const alert= useAlert()
  const token = Cookies.get('_edushark_admin_access_token');
  const his = useHistory();
 const {appid}= useParams()
  const [pageloading, setPageLoading] = useState(false);
 const [applicationStu,setApplicationStu] =useState({})
 const [paymentData,setPaymentData]=useState([])
 const [paymentDataObj,setPaymentDataObj]=useState({})
 const [somethingWrong, setSomethingWrong] = useState(false);


  useEffect(async() => {
    if(token) {
      setPageLoading(true)
        const res=await axios.get(`${apilink}/api/admin/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(!res.data.success)
          {
            Cookies.remove('_edushark_admin_access_token');
           localStorage.removeItem('_edushark_admin_access_login');
          console.clear();
          window.location.href = '/login';
          }else{
           
            if(res.data.userInfo?.isAdmin)
            {
              
            }else{
              window.location.href = '/login';
            }

          }
          setTimeout(() => {
            setPageLoading(false)
          }, 1500);
         
    }else{
      his.push("/login")
    }
  }, []);

  const allApplicationsById=async()=>{
    const res=await axios.get(`${apilink}/api/application/allApplicationsById/${appid}`)
    // console.log(res.data.result)
    if(res.data.success)
    {
      setApplicationStu(res.data.result)
      
    }else{
      setSomethingWrong(true)
      alert.error(res.data.msg)
    }
  }


  const allApplicationsByIdWithcourseID=async()=>{
    const res=await axios.get(`${apilink}/api/application/allApplicationsByIdWithcourseID/${appid}`)
    // console.log(res.data.result)
    if(res.data.success)
    {
      setPaymentData(res.data.result.courseID.cou_colleges)
      
      let ar=[]
      
       
      if(res.data.result?.course_name=="DPHARM")
      {
       
      ar= res.data.result.courseID.cou_colleges.filter((v)=>v.collageName ==  res.data.result.dpharm_form?.choose_college     ) 
      setPaymentDataObj({
        collegeId:ar[0].collegeId,
        collageName:ar[0].collageName,
        collegefees:ar[0].collegefees
      })
      }
      if(res.data.result?.course_name=="BED")
      {
        
        ar=res.data.result.courseID.cou_colleges.filter((v)=>v.collageName ==  res.data.result.bed_form?.choose_college     )
        setPaymentDataObj({
          collegeId:ar[0].collegeId,
        collageName:ar[0].collageName,
        collegefees:ar[0].collegefees
        })

      }
      if(res.data.result?.course_name=="DELED")
      {
        
        ar=res.data.result.courseID.cou_colleges.filter((v)=>v.collageName ==  res.data.result.dled_form?.choose_college     )
        setPaymentDataObj({
          collegeId:ar[0].collegeId,
        collageName:ar[0].collageName,
        collegefees:ar[0].collegefees
        })
      }
      
    }else{
      alert.error(res.data.msg)
      setSomethingWrong(true)
    }
  }

  useEffect(()=>{
    allApplicationsById()
    allApplicationsByIdWithcourseID()
  },[])

  const titleAnimation = useSpring({
    from: {
      transform: "translateY(-30px)"
    },
    to: [{ transform: "translateY(15px)" }],
    config: { mass: 3, tension: 500, friction: 25 }
  });

  const reUpdateBtn=async(app_id , user_id , statustype)=>{
    const data={
      appid:app_id,
      userID:user_id,
      statustype
    }
    const res=await axios.post(`${apilink}/api/application/reUpdateBtn`,data,{
      headers: {
        Authorization: token,
      },
    })
   
    if(res.data.success)
    {
      allApplicationsById()
      alert.success(res.data.msg)
    }else{
alert.error(res.data.msg)
setSomethingWrong(true)
    }
  }

  return (
    <>
    
    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
          {/* <h4 className='text-custom mb-4'>Student Details</h4> */}
         {
          somethingWrong ?(
            <>
            <div className="card rounded py-3  px-2 ">
                        <h5 className='text-danger text-center'>Something Went Wrong...</h5>
                    </div>
            </>
          ):(
            <>
            
            
              {
                 applicationStu.paymentStatus ?(
                  <>
                  <div className="dd_flex">
                  <h4 className='text-custom mb-4'>Student Details</h4>
                  {
                applicationStu.isReverify ?  <button onClick={()=>{
                  reUpdateBtn(applicationStu._id , applicationStu.userID , "NoUpdate")
                }} className='btn btn-warning px-3 py-2 mr-1 '> NoUpdate</button> :

                <button onClick={()=>{
                  reUpdateBtn(applicationStu._id , applicationStu.userID ,"ReUpdate")
                }} className='btn btn-danger px-3 py-2 mr-1 '> Update</button>
              }
              </div>
              {
                applicationStu?.isReverify && (
                  <>
                  <div class="alert alert-warning">
 
This application is given for re update.


</div>

                  </>
                )
              }
                  
                  </>
                 ):(
                  <>
                  <h4 className='text-custom mb-4'>Student Details</h4>
                  <div class="alert alert-danger">
  {applicationStu?.personalinfo?.applicant_name} is not paid for this application.

</div>
                  
                  </>
                 )
              }
              
              
            <div className="accordion_whole">
            <Accordion index={1} title="Course Details" data={applicationStu} allApplicationsById={allApplicationsById} paymentDataObj={paymentDataObj} paymentData={paymentData} />
            <Accordion index={2} title="Personal Details" data={applicationStu}  />
        <Accordion
        index={3}
          title="Contact & Address Details"
        data={applicationStu}
        />
        <Accordion
        index={4}
          title="Education Details"
        data={applicationStu}
        />
        <Accordion
        index={5}
          title="Documents Details"
        data={applicationStu}
        />
        <Accordion
        index={6}
          title="Payment Details"
        data={applicationStu}
        />
            </div>
            </>
          )
         }
         
           
          </div>
        </div>
      </div>
      {
        pageloading && <>
<div className="pageloading">
<div class="loadingio-spinner-bean-eater-ghxsb80unx"><div class="ldio-xz472s6kr1">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div>
</div>


        </>
      }
    </>
  )
}

export default StudentDetails