import React, { useEffect, useRef, useState } from 'react'
import './Student.css'
import SideBar from '../../component/sidebar/SideBar'
import { NavLink, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ReactPaginate from 'react-paginate';
import Avatar from 'react-avatar';
import { apilink } from '../../data/fdata';
import axios from 'axios';
import Cookies from 'js-cookie';
import { saveAs } from 'file-saver';
import { useAlert } from 'react-alert';

const Student = () => {
  const token = Cookies.get('_edushark_admin_access_token');
  const his = useHistory();
  const [pageloading, setPageLoading] = useState(false);
  const [applicationList,setApplicationList] = useState([])
  const [myData, setMyData] = useState([]);
 const alert= useAlert()

  const [pageNo, setPageNo] = useState(0);
  const [filter, setFilter] = useState("");
  const [corfilter, setCorFilter] = useState("");

  const tableContainerRef = useRef(null);

  const scrollLeft = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollBy({ left: -250, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollBy({ left: 250, behavior: 'smooth' });
    }
  };



  const perpage = 15;
  const pagevisit = pageNo * perpage;

  const dataall = myData.slice(pagevisit, pagevisit + perpage);
  const boxno = Math.ceil(myData.length / perpage);

  const pageChange = ({ selected }) => {
    setPageNo(selected);
  };

  useEffect(async() => {
    if(token) {
      setPageLoading(true)
        const res=await axios.get(`${apilink}/api/admin/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(!res.data.success)
          {
            Cookies.remove('_edushark_admin_access_token');
           localStorage.removeItem('_edushark_admin_access_login');
          console.clear();
          window.location.href = '/login';
          }else{
           
            if(res.data.userInfo?.isAdmin)
            {
              
            }else{
              window.location.href = '/login';
            }

          }
          setTimeout(() => {
            setPageLoading(false)
          }, 1500);
         
    }else{
      his.push("/login")
    }
  }, []);
  const allApplications=async()=>{
    setPageLoading(true)
    const res=await axios.get(`${apilink}/api/application/allApplications`)
    if(res.data.success)
    {
      setApplicationList(res.data.result)
      setMyData(res.data.result)
    }else{
      alert.error(res.data.msg)
    }
    setTimeout(() => {
      setPageLoading(false)
    },  1500);
  }

  useEffect(()=>{

    // if(filter=="all"){
      
    //   setMyData(applicationList);
    // } else{
    //   setMyData(applicationList.filter((v)=>v.ApplicationStatus== filter));
    // }

    if(filter){

      if(corfilter){
        setMyData(applicationList.filter((v)=>v.ApplicationStatus== filter && v.course_name == corfilter    ));
      }else{
        setMyData(applicationList.filter((v)=>v.ApplicationStatus== filter));
      }

    }else{
      if(corfilter){
        setMyData(applicationList.filter((v)=>v.course_name == corfilter    ));
      }
    }

  },[filter , corfilter ])

  useEffect(()=>{
   
    allApplications()
  },[])










  const applicationpdf=async(aid)=>{
    setPageLoading(true)
    const res=await axios.post(`${apilink}/api/pdf/application-pdf`,{
      aid
    })
   
    if(res.data.success)
    {
     alert.success(res.data.msg)
     const ress=await axios.get(`${apilink}/api/pdf/fetch-pdf/${aid}`,{ responseType: 'blob' })
         if(ress)
         {
             const pdfBlob = new Blob([ress.data], { type: 'application/pdf' });
  
               saveAs(pdfBlob, `application_pdf_${aid}.pdf`);
               const re=await axios.get(`${apilink}/api/pdf/delete-pdf/${aid}`)
               if(!re.data.success)
               {
                 his.push("something-wrong")
               }
         }
  
    }else{
    //  alert.error(res.data.msg)
    alert.error("Something went wrong!!")

    }
    setPageLoading(false)
  }


  const agreementpdf=async(aid)=>{
    setPageLoading(true)
    const res=await axios.post(`${apilink}/api/pdf/agreement-pdf`,{
      aid
    })
   
    if(res.data.success)
    {
     alert.success(res.data.msg)
     const ress=await axios.get(`${apilink}/api/pdf/fetch-pdf/${aid}`,{ responseType: 'blob' })
         if(ress)
         {
             const pdfBlob = new Blob([ress.data], { type: 'application/pdf' });
  
               saveAs(pdfBlob, `agreement_pdf_${aid}.pdf`);
               const re=await axios.get(`${apilink}/api/pdf/delete-pdf/${aid}`)
               if(!re.data.success)
               {
                 his.push("something-wrong")
               }
         }
  
    }else{
    //  alert.error(res.data.msg)
    alert.error("Something went wrong!!")

    }
    setPageLoading(false)
  }


  return (
    <>
    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
         <div className="container-fluid">
          <div className="row">
            <div className="col-12 ">
            <div className="card p-2 tab_side_bar">

            <div className="tab_side_bar_left_button">
                  <button className='sml_btn m-1' onClick={scrollLeft}><i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                  </button>
                  
                  </div>

                  <div className="tab_side_bar_right_button">
                  <button className='sml_btn m-1' onClick={scrollRight}><i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                  </button>
                  </div>
                  <div className="dd_flex">
                  <h5 className='text-custom'>All Students</h5>
                  <div className='dd_flex'>
                  <div class="form-group mr-1">
              <select class="form-control filterform" value={corfilter} onChange={(e)=>setCorFilter(e.target.value) }>
               
              <option selected hidden value="">--Course--</option>
              <option value="BED">B.ed</option>
                  <option value="DELED">D.el.ed</option>
                  <option value="DPHARM">D.pharm</option>
              </select>
            </div>
            <div class="form-group ml-1">
              <select class="form-control filterform" value={filter} onChange={(e)=>setFilter(e.target.value) }>
               
              <option selected hidden value="">--Status--</option>
                <option value="APPROVED">Approved</option>
                <option value="REJECTED">Rejected</option>
                <option value="PENDING">Pending</option>
              </select>
            </div>
            <button className="sml_btn mt5 bg-primary ml-1" onClick={()=>{
                  setFilter("")
                  setCorFilter("")
                  allApplications()
                }}> <i className=" fa fa-refresh "></i></button>
           
          </div>
                  </div>

                  <div>
         <button className='sml_btn m-1' onClick={scrollLeft}><i class="fa fa-long-arrow-left" aria-hidden="true"></i>
</button>
      <button className='sml_btn m-1' onClick={scrollRight}><i class="fa fa-long-arrow-right" aria-hidden="true"></i>
</button>
         </div>
                  <div class="table-responsive scroll_bar " ref={tableContainerRef}>
          <table class="table table-borderless dash scroll_bar_content">
            <thead>
              <tr>
              <th className='ww_60'>Operation</th>
                <th className="w_20">Name</th>
                <th className="w_20">Number</th>
                <th className="w_20">Course</th>
                <th className="w_60">College</th>
                <th className="w_20">PaymentId</th>
                <th className="w_20">Amount</th>
                <th className="w_20">PayStatus</th>

                <th className="sm_w">Status</th>
                
              </tr>
            </thead>
            <tbody className=''>
              {
                dataall.length > 0 ? (
                  <>
                   {
              dataall.map((val,ind)=>{
                  return(
                    <>
                   <tr key={ind}>
                   <td><NavLink to={`/student/${val._id}`} class="sm_btn m-1">View</NavLink>
                   <button onClick={()=>applicationpdf(val._id)} className='sml_btn m-1 bg-primary'><i className='fa fa-download'></i> Application</button>
                   <button  onClick={()=>agreementpdf(val._id)} className='sml_btn m-1 bg-primary'><i className='fa fa-download'></i> Agreement</button>
                   </td>
                <td onClick={()=>his.push(`/student/${val._id}`)} className='card_flex cur'><Avatar name={val.personalinfo?.applicant_name} size="35" round={true}  /> {val.personalinfo?.applicant_name} </td>
                <td><a href={`tel:${val.contactinfo?.applicant_phone}`}>{val.contactinfo?.applicant_phone}</a></td>
               <td>{val.course_name} &nbsp; 
               
               {val.isReverifySubmit == "NOT" && <> <i className='fa fa-info-circle' title="All Done"></i> </>}
               {val.isReverifySubmit == "PENDING" && <> <i className='fa fa-question-circle text-danger' title="Open for reupdates"></i> </>}
               {val.isReverifySubmit == "DONE" && <> <i className='fa fa-info-circle text-success' title="New Updates submited"></i> </>}


               
               </td>
                {
                  val.bed_form?.choose_college.length > 0 &&  <td>{val.bed_form?.choose_college}</td>

                }
                {
                  val.dled_form?.choose_college.length > 0 &&  <td>{val.dled_form?.choose_college}</td>

                }
                {
                  val.dpharm_form?.choose_college.length > 0 &&  <td>{val.dpharm_form?.choose_college}</td>

                }
                
                

                <td>{val.paymentId}</td>
                <td> ₹ {Number(val.paymentAmount).toFixed(2)}</td>
                <td>{val.paymentStatus ? "PAID": "UNPAID"}</td>

                <td>
                  
                  
                  {val.ApplicationStatus=="PENDING" &&  <span className='badge badge-warning'>Pending</span>}
                  {val.ApplicationStatus=="APPROVED" &&  <span className='badge badge-success'>Approved</span>}
                  {val.ApplicationStatus=="REJECTED" &&  <span className='badge badge-danger'>Rejected</span>}

                   </td>
               
              </tr>
                    </>
                  )
                })
              }
                  
                  </>
                ):(
                  <>
                  <div className="p-3 ">
                    <h5 className='text-center'>No Items Found</h5>
                  </div>
                  
                  </>
                )
              }
             
             
            </tbody>
          </table>
        </div>

        <div className="row pt-4">
         <div className="col-12">
         {
          dataall.length > 0 &&  <ReactPaginate
          previousLabel={'Prev'}
          nextLabel={'Next'}
          pageCount={boxno}
          onPageChange={pageChange}
          containerClassName={'pagination'}
          // previousLinkClassName={"prevbutton"}
          // nextLinkClassName={"nextbutton"}
          // disabledClassName={"pagedisable"}
          activeClassName={'activebutton'}
        />
         }
         </div>
        </div>

                </div>
            </div>
          </div>
         </div>
           
          </div>
        </div>
      </div>
      {
        pageloading && <>
<div className="pageloading">
<div class="loadingio-spinner-bean-eater-ghxsb80unx"><div class="ldio-xz472s6kr1">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div>
</div>


        </>
      }
    </>
  )
}

export default Student