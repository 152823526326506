import React, { useEffect, useState } from 'react'
import './Course.css'
import SideBar from '../../component/sidebar/SideBar'
import CKeditorCom from './CKeditorCom'
import DynamicInputFields from './DynamicInputFields'
import {useAlert} from 'react-alert'
import { CircularProgress } from "@mui/material";
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { apilink } from '../../data/fdata'
import axios from 'axios';
import Cookies from 'js-cookie'

const EditCourse = () => {
  const token = Cookies.get('_edushark_admin_access_token');
const his = useHistory();
const [errorFound, setErrorFound] = useState(false);
 const alert= useAlert()
 const [cname,setCname]= useState("")
 const [price,setPrice]= useState("")
 const [years,setYears]= useState("")
 const [courseimgAr,setCourseImgAr]= useState([])

 const [url,setUrl]= useState("")
 const [description,setDescription]= useState("")
 const [courseimg,setCourseImg]= useState("")
 const [otherDetails,setOtherDetails]= useState("")
 const [semDetails,setSemDetails] = useState([{ semtitle: '',semfees:''}]);
 const [loading, setLoading] = useState(false);
 const {cid}=useParams()

 const [pageloading, setPageLoading] = useState(false);




const onSubmitForm=async(e)=>{
  e.preventDefault()
  setLoading(true)

  if(courseimgAr?.name)
  {

    let formData = new FormData();
    formData.append("file", courseimgAr);
  
    const ress = await axios.post(`${apilink}/api/file/upload`, formData, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: token,
      },
    });

    if(ress.data.success)
    {
      const data={
        cou_name: cname,
        cou_price: price,
       cou_duration: years,
       cou_video: url,
       cou_description: description,
       cou_other_details: otherDetails,
       cou_sem_prices:semDetails,
       cou_image:ress.data.imageUrls[0]
    
      }
    
      const res=await axios.post(`${apilink}/api/course/updateCourseDetails/${cid}`,data,{
        headers: {
            
          Authorization: token,
        },
      })
    
      // console.log(res.data)
    
      if(res.data.success)
        {
          
          
          setTimeout(() => {
            
            alert.success("Course Details Updated successfully")
            setLoading(false)
          }, 1500);
          setTimeout(() => {
            
            his.push("/course")
          }, 4000);
        }else{
          setTimeout(() => {
            alert.success(res.data.msg)
            setLoading(false)
          }, 1500);
    
        }
 
    }else{
      setTimeout(() => {
        alert.success(ress.data.msg)
        setLoading(false)
      }, 1500);
    }



  }else{

    const data={
      cou_name: cname,
      cou_price: price,
     cou_duration: years,
     cou_video: url,
     cou_description: description,
     cou_other_details: otherDetails,
     cou_sem_prices:semDetails,
     cou_image:courseimg
  
    }
  
    const res=await axios.post(`${apilink}/api/course/updateCourseDetails/${cid}`,data,{
      headers: {
          
        Authorization: token,
      },
    })
  
    // console.log(res.data)
  
    if(res.data.success)
      {
        
        
        setTimeout(() => {
          
          alert.success("Course Details Updated successfully")
          setLoading(false)
        }, 1500);
        setTimeout(() => {
          
          his.push("/course")
        }, 4000);
      }else{
        setTimeout(() => {
          alert.success(res.data.msg)
          setLoading(false)
        }, 1500);
  
      }

  }


 

  


  
}



useEffect(async ()=>{

  if(cid)
  {
    const res=await axios.get(`${apilink}/api/course/getCourseById/${cid}`)
    if(res.data.success)
    {
      setCname(res.data.result.cou_name)
      setPrice(res.data.result.cou_price)
      setDescription(res.data.result.cou_description)
      setCourseImg(res.data.result.cou_image)
      setYears(res.data.result.cou_duration)
      setUrl(res.data.result.cou_video)
      setOtherDetails(res.data.result.cou_other_details)
      setSemDetails(res.data.result.cou_sem_prices)

    }else{
      alert.error(res.data.msg)
      setErrorFound(true)
    }
  }


},[cid])

const handelFile = (e) => {
  const { files } = e.target;
  // console.log(files[0]); files[0].type === 'application/pdf'
  if (files.length > 0) {
    if (files[0].size > 2000000) {
      alert.error(` File should be less then 2 MB`);
     
    } else if (
      files[0].type === "image/jpeg" ||
      files[0].type === "image/jpg" ||
      files[0].type === "image/png"
    ) {
     
      setCourseImgAr(files[0]);
     
    } else {
      
      alert.error(`File extension should be only jpg, png , jpeg`);
    }
   
  }
};



  return (
    <>
    
    
    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
         {
          errorFound ?(
            <>

<div className="card rounded py-3  px-2 ">
                        <h5 className='text-danger text-center'>Something Went Wrong...</h5>
                    </div>
                
            
            </>
          ):(
            <>
             <div className="container-fluid">
          <h3 className="text_custom">Update Course</h3>
            <div className="row">
              <div className="col-12">
                <form className='mt-5' onSubmit={onSubmitForm}>

                <div class="form-row">
                     <div className="form-group col-lg-6 col-md-6">
                       <label for="address">Enter Course Name:</label>
                       <input
                         type="text"
                         placeholder="Ex: BED Course"
                         className="form-control form_h"
                         name="cname"
                         value={cname}
                         onChange={(e) => setCname(e.target.value)}
                         required
                       />
                     </div>
                     <div className="form-group col-lg-6 col-md-6">
                       <label htmlFor="">Enter Duration of course (In Years)</label>
                       <input
                         type="number"
                         placeholder="Ex: 2 Years"
                         className="form-control form_h"
                         name="years"
                         value={years}
                         onChange={(e) => setYears(e.target.value)}
                         required
                       />
                     </div>
                   </div>
                   <div class="form-row">
                     <div className="form-group col-lg-6 col-md-6">
                       <label for="address">Enter Course Price:</label>
                       <input
                         type="number"
                         placeholder="Ex: 250000"
                         className="form-control form_h"
                         name="price"
                         value={price}
                         onChange={(e) => setPrice(e.target.value)}
                         required
                       />
                     </div>
                     <div className="form-group col-lg-6 col-md-6">
                       <label htmlFor="">Enter Video URL (youtube)</label>
                       <input
                         type="text"
                         placeholder="Ex: https://www.youtube.com/watch?v=fXzHr6pLqIs"
                         className="form-control form_h"
                         name="url"
                         value={url}
                         onChange={(e) => setUrl(e.target.value)}
                         required
                       />
                     </div>
                   </div>
                   <div class="form-row">
                     <div className="form-group col-lg-6 col-md-6">
                       <label for="address">Upload Course Image:</label>
                       <input
                         type="file"                         
                         className="form-control form_h"
                         name="image"
                         accept=".png, .jpeg , .jpg "
                          // disabled
                          onChange={handelFile}
                         
                       />
                       <span className='cur fn_14 text-custom' data-toggle="modal"
                            data-target="#myModal">View Image</span>

                       <div class="modal fade" id="myModal">
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">View Image</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div class="modal-body">

              <img src={courseimg} alt="" className='mod_img' />
              
            
            </div>
          </div>
        </div>
      </div>

                     </div>
                     <div className="form-group col-lg-6 col-md-6">
                       <label htmlFor="">Enter Description</label>
                       
                          <textarea
                            class="form-control"
                            rows="3"
                            placeholder="Enter Course Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                          ></textarea>
                      
                       
                     </div>
                   </div>
                   <DynamicInputFields  semesterFields={semDetails} setSemesterFields ={setSemDetails} />

                   <div className="form-group">
                     <label>Enter Other Details:</label>
                     <CKeditorCom
                       text={otherDetails}
                       setText={setOtherDetails}
                     />
                   </div>

                   <div className="text-right">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Update Details
                      </button>
                    </div>
                    
                    {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} />
                      </div>
                    )}
                   
                </form>
                
              </div>
            </div>

          </div>
         
            
            
            </>
          )
         }
           
          </div>
        </div>
      </div>

      {
        pageloading && <>
<div className="pageloading">
<div class="loadingio-spinner-bean-eater-ghxsb80unx"><div class="ldio-xz472s6kr1">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div>
</div>


        </>
      }
    </>
  )
}

export default EditCourse