import React, { useEffect, useState } from 'react'
import './College.css'
import SideBar from '../../component/sidebar/SideBar'
import { CircularProgress } from '@mui/material'
import { useAlert } from 'react-alert'
import Collegelist from '../../component/studentcompo/Collegelist'
import { apilink } from '../../data/fdata'
import axios from 'axios'
import Cookies from 'js-cookie'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const AddCollege = () => {

const alert=useAlert()
const [loading,setLoading] =useState(false)
const [cloading,setCLoading] =useState(false)

const [pageloading, setPageLoading] = useState(false);

const [collegeName,setCollegeName] = useState("")
const [collegeImage,setCollegeImage] = useState([])
const [location,setLocation] = useState("")
const [description,setDescription]= useState("")

const [collegeList,setCollegeList] = useState([])
const [courseList,setCourseList] = useState([])



const token = Cookies.get('_edushark_admin_access_token');
const his = useHistory();




const [collegeId, setCollegeId] = useState("");
const [courseId, setCourseId] = useState("");
const [courseName, setCourseName] = useState("");



useEffect(async() => {
  if(token) {
    setPageLoading(true)
      const res=await axios.get(`${apilink}/api/admin/authVerify`,{
          headers: {
            Authorization: token,
          },
        })
        // console.log(res.data)
        if(!res.data.success)
        {
          Cookies.remove('_edushark_admin_access_token');
         localStorage.removeItem('_edushark_admin_access_login');
        console.clear();
        window.location.href = '/login';
        }else{
         
          if(!res.data.userInfo?.isAdmin)
          {
            window.location.href = '/login';
          }

        }
        setTimeout(() => {
          setPageLoading(false)
        }, 1500);
       
  }else{
    his.push("/login")
  }
}, []);


const getcollegedata=async()=>{

  const res=await axios.get(`${apilink}/api/college/getColleges`)
  // console.log(res.data)

  if(res.data.success)
  {
    setCollegeList(res.data.result)
  }else{
    alert.error(res.data.msg)
  }

}

const getcoursedata=async()=>{

  const res=await axios.get(`${apilink}/api/course/getcourses`)
  // console.log(res.data)

  if(res.data.success)
  {
    setCourseList(res.data.result)
  }else{
    alert.error(res.data.msg)
  }

}


  const addCollege=async(e)=>{
    e.preventDefault()
    setLoading(true)

    let formData = new FormData();
      
    Array.from(collegeImage).forEach(image => {
      formData.append("file", image);
  });

  const res = await axios.post(`${apilink}/api/file/upload`, formData, {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: token,
    },
  });
  // console.log(res.data)

  if(res.data.success)
  {
    const data={
      col_name:collegeName,
      col_images:res.data.imageUrls,
      col_location:location,
      col_desc:description

    }
    const ress=await axios.post(`${apilink}/api/college/postCollege`,data,{
      headers: {
        
        Authorization: token,
      },
    })
    // console.log(ress.data)
    if(ress.data.success) 
    {
      setCollegeList([])
      getcollegedata()
      
    alert.success(ress.data.msg)

      setCollegeName("")
      setCollegeImage([])
      setLocation("")
      setDescription("")



      

    }else{
      
    alert.error(ress.data.msg)
    }

  }else{
    alert.error(res.data.msg)
  }

    // postCollege

    setLoading(false)



  }

  const addCourseToCollege=async(e)=>{
    e.preventDefault()
    setCLoading(true)
const data={
  collegeId,
  courseId,
  courseName
}
// console.log(data)

const res=await axios.post(`${apilink}/api/college/updateCollege`,data,{
  headers: {
        
    Authorization: token,
  }
})
// console.log(res.data)
if(res.data.success){
  getcollegedata()
  setCollegeId("")
  setCourseId("")
  setCourseName("")
  alert.success(res.data.msg)
  
}else{
  setCollegeId("")
  setCourseId("")
  alert.error(res.data.msg) 
}
    setCLoading(false)

  }


  const handelimage = (e) => {
    const { files } = e.target;
    let f = false;
    let filetype = ['image/jpeg', 'image/jpg', 'image/png'];
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        // console.log(files[i].type);
        if (files[i].size > 2000000) {
          f = true;
          // console.log('ok');
        }

        if (!filetype.includes(files[i].type)) {
          f = true;
          // console.log('hi');
        }
      }
      if (f) {
        // console.log(f);
        alert.error(
          'Upload images with proper file size (2MB) & extension ( jpg, png , jpeg)'
        );
        f = false;
      }else{
        // console.log();
        setCollegeImage(e.target.files)
      }
    }
  };

  useEffect(()=>{
    getcollegedata()
    getcoursedata()
  },[])

  return (
    <>
    
    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12 mb-3">
                  <div className="card p-3">
                    <h4 className='text-custom mb-4'>Add New College</h4>
                    <form onSubmit={addCollege}>

                    <div className="form-group ">
                       <label for="address">Enter College Name:</label>
                       <input
                         type="text"
                         placeholder="Ex: ABC Training Institute"
                         className="form-control form_h"
                         name="name"
                         value={collegeName}
                         onChange={(e) => setCollegeName(e.target.value)}
                         required
                       />
                     </div>

                     <div className="form-group ">
                       <label for="address">Upload College Images:</label>
                       <input
                         type="file"
                        
                         className="form-control form_h"
                         name="file"
                         multiple
                         accept=".png, .jpeg , .jpg"
                        
                         onChange={handelimage}
                         required
                       />
                     </div>

                     <div className="form-group ">
                       <label for="address">Enter College Location (iframe Url):</label>
                       <input
                         type="text"
                         placeholder="Ex:  https://www.google.com/maps/embed..."
                         className="form-control form_h"
                         name="location"
                         value={location}
                         onChange={(e) => setLocation(e.target.value)}
                         required
                       />
                     </div>
                     <div className="form-group ">
                       <label htmlFor="">Enter Description</label>
                       
                          <textarea
                            class="form-control"
                            rows="3"
                            placeholder="Enter College Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                          ></textarea>
                      
                       
                     </div>

                    <div className="text-right">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Submit
                      </button>
                    </div>
                    
                    {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} />
                      </div>
                    )}
                   

                    </form>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12 mb-3">
                  <div className="card p-3">
                    <h4 className='text-custom mb-4'>Add Courses to College</h4>
                    <form onSubmit={addCourseToCollege}>

                    <div className="form-group ">
                    <label for="address">Choose College Name:</label>
                       <select
                          class="form-control form_h"
                          value={collegeId}
                          onChange={(e) => setCollegeId(e.target.value)}
                          required
                        >
                          <option selected hidden value="">
                            --Choose--
                          </option>
                        {
                          collegeList?.map((val,ind)=>{
                            return(
                              <>
                              
                              
                              <option  key={ind} value={val._id}>
                                  {val.col_name}
                                </option>
                              </>
                            )
                          })
                        }
                        </select>
                       
                     </div>
                     <div className="form-group ">
                    <label for="address">Choose Course Name:</label>
                       <select
                          class="form-control form_h"
                          value={courseId}
                          onChange={(e) => {
                            setCourseId(e.target.value)
                            let ar=courseList.filter((v)=>v._id==e.target.value)
                            setCourseName(ar[0].cou_name)
                          }}
                          required
                        >
                          <option selected hidden value="">
                            --Choose--
                          </option>
                          

                          {
                            courseList?.map((val,ind)=>{
                              return(
                                <>
                                
                                
                                <option  key={ind} value={val._id}>
                                    {val.cou_name}
                                  </option>
                                </>
                              )
                            })
                          }
                          
                        </select>
                       
                     </div>
                    

                    

                    <div className="text-right">
                      <button
                        type="submit"
                        className={
                          cloading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={cloading}
                      >
                        Submit
                      </button>
                    </div>
                    
                    {cloading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} />
                      </div>
                    )}
                   

                    </form>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                {
                  collegeList.length > 0 && <Collegelist list={collegeList} />
                }
              </div>
            </div>
         
           
          </div>
        </div>
      </div>
      {
        pageloading && <>
<div className="pageloading">
<div class="loadingio-spinner-bean-eater-ghxsb80unx"><div class="ldio-xz472s6kr1">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div>
</div>


        </>
      }
    </>
  )
}

export default AddCollege