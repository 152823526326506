import React from 'react'
import SideBar from '../../component/sidebar/SideBar'
import { useState } from 'react';
import { useAlert } from 'react-alert';
import Cookies from 'js-cookie';
import {NavLink , useHistory , useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { apilink } from '../../data/fdata';
import axios from 'axios';
import { useEffect } from 'react';
import { CircularProgress } from "@mui/material";



const VideoCourseDetails = () => {
    const token = Cookies.get('_edushark_admin_access_token');
    const his = useHistory();
    const [pageloading, setPageLoading] = useState(false);
    const [loading,setLoading] =useState(false)
    const [popup, setPopup] = useState(false);

    const [cont_id, setCont_id] = useState('');
    const [title, setTitle] = useState('');
     const [sml_desc, setSml_desc] = useState('');
     const [ video_url, setVideo_url] = useState('');
     const [contentList,setContentList]=useState([])

     const {cid}=useParams()


    const alert= useAlert()


    useEffect(async() => {
        if(token) {
          setPageLoading(true)
            const res=await axios.get(`${apilink}/api/admin/authVerify`,{
                headers: {
                  Authorization: token,
                },
              })
              // console.log(res.data)
              if(!res.data.success)
              {
                Cookies.remove('_edushark_admin_access_token');
               localStorage.removeItem('_edushark_admin_access_login');
              console.clear();
              window.location.href = '/login';
              }else{
               
                if(!res.data.userInfo?.isAdmin)
                {
                  window.location.href = '/login';
                }
      
              }
              setTimeout(() => {
                setPageLoading(false)
              }, 1500);
             
        }else{
          his.push("/login")
        }
      }, []);
      const getcontentbyid=async()=>{
        const res=await axios.get(`${apilink}/api/purchescourse/getcontentbyid/${cid}`)
        // console.log(res.data)
        if(res.data.success)
        {
            setContentList(res.data.result )
            // setContentDet(res.data.result.cor_content )


        }else{
            alert.error(res.data.msg)
        }
      }

      const onUpdateVideo=async(e)=>{
        e.preventDefault()
setLoading(true)
        const data={
            cor_Id:cid,
            cont_id,
            title,
            sml_desc,
            video_url
        }
        const res=await axios.post(`${apilink}/api/purchescourse/updatecoursecontent`,data,{
            headers: {
                Authorization: token,
              },
        })
        // console.log(res.data)
        if(res.data.success)
        {
       
            setTimeout(() => {
                getcontentbyid()
                alert.success(res.data.msg)
                setLoading(false)
                setPopup(false)
            }, 1500);
            
        }else{
            setTimeout(() => {
                alert.error(res.data.msg)
                setLoading(false)
                setPopup(false)

            }, 1500);
            
        }
      }
      

      useEffect(()=>{
        if(cid)
        {
            getcontentbyid()
        }
        
      },[cid])

      // useEffect(()=>{
      //   if(cont_id)
      //   {
      //       let ar=contentList.filter((v)=>v._id == cont_id)
      //       // console.log(ar)
      //       setTitle(ar[0].title)
      //   setSml_desc(ar[0].sml_desc)
      //   setVideo_url(ar[0].video_url)
      //   }
       
      // },[cont_id])

  return (
    <>
    
    {
    popup &&  <div className="model_box">
    <div className="inner_model">
      <div className="cross" onClick={()=>{
        setPopup(false)
        setCont_id("")
        setTitle('')
        setSml_desc('')
        setVideo_url('')
      }}>&times;</div>
      <div>
       <div className="text-center">
        <h5>Update Course Video</h5>
       
       </div>
       <form className="my-4" onSubmit={onUpdateVideo}>
        <div className="form-group ">
                       <label htmlFor=""> Video Title</label>
                       <input
                         type="text"
                         placeholder="Enter Video Title"
                         className="form-control form_h"
                         name="title"
                         value={title}
                         onChange={(e) => setTitle(e.target.value)}
                         required
                       />
                     </div>
                     <div className="form-group ">
                       <label htmlFor=""> Video Url</label>
                       <input
                         type="text"
                         placeholder="Enter Video Url"
                         className="form-control form_h"
                         name="video_url"
                         value={video_url}
                         onChange={(e) => setVideo_url(e.target.value)}
                         required
                       />
                     </div>
                     <div className="form-group ">
                       <label htmlFor="">Video Description</label>
                       
                          <textarea
                            class="form-control"
                            rows="3"
                            placeholder="Enter Video Description"
                            name="sml_desc"
                            value={sml_desc}
                            onChange={(e) => setSml_desc(e.target.value)}
                            required
                          ></textarea>
                      
                       
                     </div>
                     <div className="text-center">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Save
                      </button>
                      {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} />
                      </div>
                    )}
                    </div>
        </form>
      </div>
    </div>
  </div>
   }
    
    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
          <div className="container-fluid">
            <div className="row mb-5">
            <div className="col-lg-5 col-md-6 col-12 mx-auto mb-3">
              <img src={contentList.cor_image} alt="" className='purches_img' />

            </div>
            <div className="col-lg-7 col-md-6 col-12 mx-auto mb-3">
              <h2 className='text-custom font-weight-bold'>{contentList.cor_name}</h2>
              <p className='m-0'>{contentList.cor_description}</p>
              <br />
              <h5>Price: <span className='text-custom'>{contentList.cor_price}</span></h5>

              <h5 className='mb-4'>Duration: <span className='text-custom'>{contentList.cor_duration}</span></h5>
              
              <NavLink to={`/edit-purchase-course/${contentList._id}`} className='sm_btn'>Edit &nbsp; <i className='fa fa-edit'></i></NavLink>
              
              </div>
            </div>
            <h3>All Video Content</h3>
             <div className="row mt-4">
                <div className="col-12">
                   
                   {
                    contentList?.cor_content?.length > 0 ?(
                        <>
                         {
                        contentList?.cor_content?.map((val,ind)=>{
                            return(
                                <>
                                
                                <div className="card p-3 mb-3" key={ind}>
                        <div>
                       <h5 className='text-custom'> <a href={val.video_url} target='_blank'>{val.title} </a></h5>
                        </div>
                        <p className='m-0'>{val.sml_desc}</p>
                        <div className="text-right" onClick={()=>{
                            setPopup(true)
                            // setCont_id(val._id)

                            let ar=contentList.cor_content.filter((v)=>v._id == val._id)
                            // console.log(ar)
                            setTitle(ar[0].title)
                        setSml_desc(ar[0].sml_desc)
                        setVideo_url(ar[0].video_url)
                        }}>
                            <i className='fa fa-edit text-custom cur'></i>
                        </div>
                    </div>
                                </>
                            )
                        })
                    }
                        </>
                    ):(
                        <>
                        <div className="my-5 text-center">
<h5>No Item Found</h5>
                        </div>
                        
                        
                        </>
                    )
                   }
                </div>
             </div>
          

          </div>
         
           
          </div>
        </div>
      </div>

      {
        pageloading && <>
<div className="pageloading">
<div class="loadingio-spinner-bean-eater-ghxsb80unx"><div class="ldio-xz472s6kr1">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div>
</div>


        </>
      }
    
    </>
  )
}

export default VideoCourseDetails