import React from 'react'
import { Link, NavLink } from 'react-router-dom';
import "./NavBar.css"
import { headerNav, path } from '../../data/fdata'
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import { deepOrange } from '@mui/material/colors';
import Cookies from 'js-cookie';


const NavBar = () => {

    const [logoutstatus, setLogoutstatus] = useState(false);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const openn = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const logout = () => {
      Cookies.remove('_edushark_admin_access_token');
      localStorage.removeItem('_edushark_admin_access_login');
     console.clear();
        window.location.href = '/';
      };
  return (
    <>
    
    <div className="student_nav">
        <div className="student_navbar">
        <div className="student_flex">
                <MenuIcon
                  className="cur mr-3 resp_dis text-white"
                  onClick={() => setOpen(true)}
                />
                <NavLink
                  to="/dashboard"
                  className="logo_nav"
                >
                  <img
                    src={`${path}/image/stu_logo.png`}
                    alt=""
                    className="stu_logoimg"
                  />
                </NavLink>
              </div>

              <div>
                <div className="">
                  
                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={openn ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openn ? 'true' : undefined}
                    >
                      <Avatar
                        sx={{ bgcolor: deepOrange[500], width: 32, height: 32 }}
                       
                      ></Avatar>
                    </IconButton>
                  </Tooltip>
                </div>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={openn}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  className="right_nav_link"
                >
                  <MenuItem>
                    <NavLink to="/account">
                      <ListItemIcon>
                        <PersonIcon fontSize="small" />
                      </ListItemIcon>
                      Account details
                    </NavLink>
                  </MenuItem>
                  <MenuItem>
                    <NavLink to="/setting">
                      <ListItemIcon>
                        <SettingsSuggestIcon fontSize="small" />
                      </ListItemIcon>
                      Setting
                    </NavLink>
                  </MenuItem>
                  <MenuItem>
                    <NavLink to="/orders">
                      <ListItemIcon>
                        <ListAltIcon fontSize="small" />
                      </ListItemIcon>
                      Orders
                    </NavLink>
                  </MenuItem>
                  <Divider />

                  <MenuItem onClick={() => setLogoutstatus(true)}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>


                <Drawer open={open} onClose={() => setOpen(false)}>
              <Toolbar className="nav-wid">
                <NavLink to="/dashboard" onClick={() => setOpen(false)}>
                <img
                    src={`${path}/image/stu_logo.png`}
                    alt=""
                    className="stu_logoimg pl-2 "
                  />
                </NavLink>
              </Toolbar>

              {/* <Divider /> */}

              <List disablePadding className="listsize ">
                {headerNav.map((v) => {
                  return (
                    <>
                    {
                      v.display=="Purchase Classes" ?(
                        <>
                         <ListItem
                        button
                        component="a"
                        // to={`${v.path}`}
                        onClick={() => {
                          setOpen(false)
                          window.open("https://class.edushark.in", "_blank")
                        }}
                      >
                        <ListItemText primary={`${v.display}`} />
                      </ListItem>

                        </>
                      ):(
                        <>
                         <ListItem
                        button
                        component={Link}
                        to={`${v.path}`}
                        onClick={() => setOpen(false)}
                      >
                        <ListItemText primary={`${v.display}`} />
                      </ListItem>

                        </>
                      )
                    }
                     
                    </>
                  );
                })}
              </List>
            </Drawer>
              </div>


        </div>

    </div>


    {logoutstatus && (
              <div className="modbox">
                <div className="smbox">
                  <div
                    className="btn_close"
                    onClick={() => setLogoutstatus(false)}
                  >
                    X
                  </div>
                  <p>Are you sure about Logout? </p>
                  <button className="btn btn-primary" onClick={() => logout()}>
                    Logout
                  </button>
                </div>
              </div>
            )}
    </>
  )
}

export default NavBar