import React, { useEffect, useState } from 'react'

import SideBar from '../../component/sidebar/SideBar';
import ReactPaginate from 'react-paginate';
import { apilink } from '../../data/fdata';
import axios from 'axios';
import Cookies from 'js-cookie';
import { NavLink, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useAlert } from 'react-alert';
import './UserSearch.css'

import { useRef } from 'react';

const Users = () => {

 

    const [pageloading, setPageLoading] = useState(false);
    const [myData, setMyData] = useState([]);
    const [saveData, setSaveData] = useState([]);
    const [searchText,setSearchText]=useState("")

    const token = Cookies.get('_edushark_admin_access_token');
  const his = useHistory();
 const alert= useAlert()

    const [pageNo, setPageNo] = useState(0);
    const [filter, setFilter] = useState("all");


    const tableContainerRef = useRef(null);

  const scrollLeft = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollBy({ left: -250, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollBy({ left: 250, behavior: 'smooth' });
    }
  };
  
    const perpage = 15;
    const pagevisit = pageNo * perpage;
  
    const dataall = myData.slice(pagevisit, pagevisit + perpage);
    const boxno = Math.ceil(myData.length / perpage);
  
    const pageChange = ({ selected }) => {
      setPageNo(selected);
    };

    useEffect(async() => {
        if(token) {
          setPageLoading(true)
            const res=await axios.get(`${apilink}/api/admin/authVerify`,{
                headers: {
                  Authorization: token,
                },
              })
              // console.log(res.data)
              if(!res.data.success)
              {
                Cookies.remove('_edushark_admin_access_token');
               localStorage.removeItem('_edushark_admin_access_login');
              console.clear();
              window.location.href = '/login';
              }else{
               
                if(!res.data.userInfo?.isAdmin)
                {
                  window.location.href = '/login';
                }
      
              }
              setTimeout(() => {
                setPageLoading(false)
              }, 1500);
             
        }else{
          his.push("/login")
        }
      }, []);

    useEffect(()=>{

        if(filter=="all"){
          
          setMyData(saveData);
        } else{
            if(filter=="Active")
            {
                setMyData(saveData.filter(v=>v.isActive==true));
            }else{
                setMyData(saveData.filter(v=>v.isActive==false));
            }
          
        }
    
      },[filter])




const allusers=async()=>{
    const res=await axios.get(`${apilink}/api/user/allusers`,
    {
        headers: {
            Authorization: token,
          },

    })
    // console.log(res.data)
    setMyData(res.data.result)
    setSaveData(res.data.result)
}

const updateUserByAdmin=async(id)=>{
setPageLoading(true)
    const res=await axios.get(`${apilink}/api/user/updateUserByAdmin/${id}`,{
        headers: {
            Authorization: token,
          },
    })
    // console.log(res.data)
    if(res.data.success){
        alert.success(res.data.msg)
        allusers()

    }else{
        alert.error(res.data.msg)
    }
setTimeout(() => {
    setPageLoading(false)
}, 1500);
  }
  const deleteUser=async(id)=>{
    setPageLoading(true)
    const res=await axios.get(`${apilink}/api/user/deleteUserByAdmin/${id}`,{
        headers: {
            Authorization: token,
          },
    })
    // console.log(res.data)
    if(res.data.success){
        alert.success(res.data.msg)
        allusers()

    }else{
        alert.error(res.data.msg)
    }
setTimeout(() => {
    setPageLoading(false)
}, 1500);
  }

useEffect(()=>{
    allusers()
},[])

const handelSearch=(e)=>{
  setSearchText(e.target.value)


  let arr =saveData.filter(
    (v) =>
      v.name.toLowerCase().includes(e.target.value.toLowerCase()) 
  );
  // console.log(arr)
  if(e.target.value.length>0)
    {
      setMyData(arr)

    }else{
      setMyData(saveData)
    }
}

  return (
   <>
   
   <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">

            <div className="container-fluid">
            {/* <ScrollableTable /> */}
                <div className="row">
                    
                    <div className="col-12">
                    
                        <div className="card p-3 tab_side_bar">

                        <div className="tab_side_bar_left_button">
                  <button className='sml_btn m-1' onClick={scrollLeft}><i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                  </button>
                  
                  </div>

                  <div className="tab_side_bar_right_button">
                  <button className='sml_btn m-1' onClick={scrollRight}><i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                  </button>
                  </div>

                        <div className="dd_flex">
                        <h5 className='text-custom mb-2'>All Users</h5>
                  <div className='dd_flex'>
            <div class="form-group">
              <select class="form-control filterform" value={filter} onChange={(e)=>setFilter(e.target.value) }>
               
                <option value="all">All</option>
                <option value="Active">Active</option>
                <option value="Pending">Pending</option>
              </select>
            </div>
            </div>
           
          </div>
          <div className="user_search">
          <input class="search" type="search" placeholder="Search by name..." required value={searchText} onChange={handelSearch} />

          </div>

          <div>
         <button className='sml_btn m-1' onClick={scrollLeft}><i class="fa fa-long-arrow-left" aria-hidden="true"></i>
</button>
      <button className='sml_btn m-1' onClick={scrollRight}><i class="fa fa-long-arrow-right" aria-hidden="true"></i>
</button>
         </div>
        
          {
                dataall.length > 0 ?(
                    <>
                    <div class="table-responsive  scroll_bar" ref={tableContainerRef} >
          <table class="table table-borderless dash scroll_bar_content">
            <thead>
              <tr>
             
                <th className="w_20">Name</th>
                <th className="w_20">Phone</th>
                <th className="w_60">Email</th> 
                <th className="w_20">Role</th>               
                <th className="w_20">Date</th>
                <th className="w_60">ID</th> 
                <th className='w_20'>Operation</th>
                
              </tr>
            </thead>
            <tbody className=''>

                {
                     dataall?.map((val,ind)=>{
                        return(
                          <>
                          <tr key={ind}>
                           
                              <td>
                                <NavLink to={`/user/${val._id}`}>{val.name}</NavLink>
                              </td>
                              <td><a href={`tel:${val.phone}`}>{val.phone}</a></td>
                              <td>{val.email}</td>
                              <td>
                               {val.role}
                              </td>
                              <td>{new Date(val.createdAt).toLocaleDateString('en-GB')}</td>
                              <td><small>{val._id}</small></td>
                              <td> {val.isActive ?<> <span className='badge badge-success'>Active</span>  </>  : <> <span  className='badge badge-warning'>Pending</span> </> }
                             &nbsp; <span onClick={()=>updateUserByAdmin(val._id)} className='badge badge-dark cur'>Update</span>  
                              </td>
                      
                          
                     
                    </tr>
                          </>
                        )
                      })
                }
             
             
            </tbody>
          </table>
        </div>
                    </>
                ):(
                    <>
                    
                    <div className="  text-center">
                        <p >No item Found</p>
                    </div>
                    
                    
                    </>
                )
              }

                        



                        <div className="row pt-4">
         <div className="col-12">
         {
          dataall.length > 0 &&  <ReactPaginate
          previousLabel={'Prev'}
          nextLabel={'Next'}
          pageCount={boxno}
          onPageChange={pageChange}
          containerClassName={'pagination'}
          // previousLinkClassName={"prevbutton"}
          // nextLinkClassName={"nextbutton"}
          // disabledClassName={"pagedisable"}
          activeClassName={'activebutton'}
        />
         }
         </div>
        </div>


                        </div>
                    </div>
                </div>
            </div>
         
           
          </div>
        </div>
      </div>
      {
        pageloading && <>
<div className="pageloading">
<div class="loadingio-spinner-bean-eater-ghxsb80unx"><div class="ldio-xz472s6kr1">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div>
</div>


        </>
      }
   
   </>
  )
}

export default Users