import React from 'react'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import { apilink, path } from '../../data/fdata'

const CourseList = ({courseList}) => {
  return (
    <div className="card p-2">
    <div className="dd_flex mb-3">
           <h5 className='text-custom'>Courses</h5>
           
           <NavLink to="/add-course" class="sm_btn ">Add Course</NavLink>
           </div>
           {
            courseList.length > 0 ?<>

<div class="table-responsive listmy ">
   <table class="table table-borderless dash">
     <thead>
       <tr>
         <th className="w_60">Course Details</th>
         
         <th className="w_20">Price</th>               
         
         
       </tr>
     </thead>
     <tbody className=''>
       {
         courseList?.map((val,ind)=>{
           return(
             <>
             <tr>
         <td className='d_flex'>
           <img src={val.cou_image} alt="" className='td_img' />
           <div className='ml-2'>
             <p className=' m-0 short_p'>{val.cou_name}</p>
             <NavLink to={`/edit-course/${val._id}`} className='badge badge-primary ' >View</NavLink>
           </div>
            </td>
         <td>₹{val.cou_price}</td>
         
         
       </tr>
             </>
           )
         })
       }
      
     </tbody>
   </table>
 </div>
            
            </>:<>
            
            <div className="text-center p-3">
              <h5>No item Found</h5>
            </div>
            
            </>
           }
          

           
         </div>  
  )
}

export default CourseList