import React, { useState } from 'react'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import { path } from '../../data/fdata'
import ReactPaginate from 'react-paginate';

const Collegelist = ({list}) => {

    const [myData, setMyData] = useState(list);
  const [pageNo, setPageNo] = useState(0);


  const perpage = 5;
  const pagevisit = pageNo * perpage;

  const dataall = myData.slice(pagevisit, pagevisit + perpage);
  const boxno = Math.ceil(myData.length / perpage);

  const pageChange = ({ selected }) => {
    setPageNo(selected);
  };

  return (
    <>
    
    <div className="card p-3 mt-5">
                  <h4 className='text-custom'>All Colleges</h4>
                  <div class="table-responsive  ">
          <table class="table table-borderless dash">
            <thead>
              <tr>
                <th className="w_20">Images</th>
                <th className="w_20">Name</th>
                <th className="w_60">Description</th>               
                <th className="w_60">Courses</th>
                <th className='w_60'>Location</th>
                
              </tr>
            </thead>
            <tbody className=''>
              {
                dataall?.map((val,ind)=>{
                  return(
                    <>
                    <tr key={ind}>
                
                    <td className=''>
                      {
                        val.col_images.length > 0 && <img src={val.col_images[0]} alt="" className='big_td_img' />
                      }
           
           
            
             <div className="text-center mt-2">
              <NavLink to={`/edit-college/${val._id}`} class="badge badge-dark m-1">Edit</NavLink>
             <span className='badge badge-primary cur m-1' data-toggle="modal"
                            data-target={`#myModal${val._id}`} >View</span> 
                            <div class="modal fade" id={`myModal${val._id}`}>
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">View Images</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            <div class="modal-body">
              <div className="img_flex">
                {
                  val.col_images?.map((va,index)=>{
                    return (
                      <div className="img_flex_item" key={index}>
                  <img src={va} alt="" />
                </div>
                    )
                  })
                }
              </div>
            
            </div>
          </div>
        </div>
      </div>
             </div>
           
            </td>
            <td>{val.col_name}</td>
            
            <td>
              <p className='big_p'>{val.col_desc}</p>
            </td>
            <td>{val.col_courses.length > 0 && val.col_courses?.map((va,index)=>{
              return (
                <>
                
               <p> {va.courseName } {val.col_courses.length !=index+1 ? " , " :" ."}</p>
                
                </>
              )
            })} </td>
            <td> <iframe src={val.col_location} width="100%" height="150" style={{'border':"none"}}  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></td>
           
               
              </tr>
                    </>
                  )
                })
              }
             
            </tbody>
          </table>
        </div>


        

        <div className="row pt-4">
         <div className="col-12">
         {
          dataall.length > 0 &&  <ReactPaginate
          previousLabel={'Prev'}
          nextLabel={'Next'}
          pageCount={boxno}
          onPageChange={pageChange}
          containerClassName={'pagination'}
          // previousLinkClassName={"prevbutton"}
          // nextLinkClassName={"nextbutton"}
          // disabledClassName={"pagedisable"}
          activeClassName={'activebutton'}
        />
         }
         </div>
        </div>

                </div>
    </>
  )
}

export default Collegelist