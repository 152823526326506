import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CKeditorCom = ({ text, setText }) => {
  const hadelchange = (e, editor) => {
    const data = editor.getData();
    // console.log(data);
    setText(data);
  };
  return (
    <>
      <CKEditor  editor={ClassicEditor} data={text} onChange={hadelchange} />
    </>
  );
};

export default CKeditorCom;