


  import React, { useState } from 'react';

function DynamicInputFields({semesterFields, setSemesterFields}) {


  // Function to add a new semester field
  const addSemesterField = () => {
    setSemesterFields([...semesterFields, { semtitle: '', semfees: '' }]);
  };

  // Function to handle changes in semester field values
  const handleSemesterFieldChange = (index, event, field) => {
    const newSemesterFields = [...semesterFields];
    // console.log(newSemesterFields)
    newSemesterFields[index][field] = event.target.value;
    setSemesterFields(newSemesterFields);
  };

  const deleteSemesterField = (index) => {
    const newSemesterFields = [...semesterFields];
    newSemesterFields.splice(index, 1);
    setSemesterFields(newSemesterFields);
  };

  // Function to render the semester fields
  const renderSemesterFields = () => {
    return semesterFields.map((semester, index) => (
      <div className="relative card p-3 mb-2">
         <div class="form-row" key={index}>
        <div className="form-group col-lg-6 col-md-6">
                       <label for="address">Enter Semester Title:</label>
                       <select
                          class="form-control form_h"
                          value={semester.semtitle}
                         onChange={(event) => handleSemesterFieldChange(index, event, 'semtitle')}
                          required
                        >
                          <option selected hidden value="">
                            --Choose--
                          </option>
                          <option value="Admission Fee">Admission Fee</option>
                          <option value="1st Semester Fee">1st Semester Fee</option>
                          <option value="2nd Semester Fee">2nd Semester Fee</option>
                          <option value="3rd Semester Fee">3rd Semester Fee</option>
                          <option value="4th Semester Fee">4th Semester Fee</option>
                        </select>
                       
                     </div>
                     <div className="form-group col-lg-6 col-md-6">
                       <label for="address">Enter Semester Fees:</label>
                       <input
                         type="text"
                        className="form-control form_h"
                        
                         placeholder="Ex: 15000"
                         value={semester.semfees}
                         onChange={(event) => handleSemesterFieldChange(index, event, 'semfees')}
                         required
                       />
                     </div>
                    
        
      </div>
      {
        semesterFields.length > 1 && <div className='delete_box'>
        <p className='m-0 cur' onClick={() => deleteSemesterField(index)}> <i className='fa fa-trash'></i> </p>
        </div>
      }
      </div>

      
     
    ));
  };

  return (
    <div className='mb-4'>
      {renderSemesterFields()}
      
      <button className='sm_btn' onClick={addSemesterField}>Add Semester</button>
    </div>
  );
}

export default DynamicInputFields;
