import React, { useEffect, useState } from 'react'
import SideBar from '../../component/sidebar/SideBar'
import './Account.css'
import Cookies from 'js-cookie';
import { NavLink, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { apilink } from '../../data/fdata';
import axios from 'axios';

const Account = () => {
  const [userData, setUserData] = useState([]);
  const [pageloading, setPageLoading] = useState(false);
  const token = Cookies.get('_edushark_admin_access_token');
  const his = useHistory();

  useEffect(async() => {
    if(token) {
      setPageLoading(true)
        const res=await axios.get(`${apilink}/api/admin/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(!res.data.success)
          {
            Cookies.remove('_edushark_admin_access_token');
           localStorage.removeItem('_edushark_admin_access_login');
          console.clear();
          window.location.href = '/login';
          }else{
           
            if(!res.data.userInfo?.isAdmin)
            {
              window.location.href = '/login';
            }else{
              setUserData(res.data.userInfo)
            }
  
          }
          setTimeout(() => {
            setPageLoading(false)
          }, 1500);
         
    }else{
      his.push("/login")
    }
  }, []);
  

  return (
    <>
    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                <div className="card p-3">
                  <h4>Name: {userData?.name}</h4>
                  <h4>Email: {userData?.email}</h4>
                  <div className="text-left mt-2">
                  <NavLink to="/setting" className='sm_btn'>Update Password</NavLink>
                  </div>

                </div>
                </div>
              </div>
            </div>
         
           
          </div>
        </div>
      </div>
      {
        pageloading && <>
<div className="pageloading">
<div class="loadingio-spinner-bean-eater-ghxsb80unx"><div class="ldio-xz472s6kr1">
<div><div></div><div></div><div></div></div><div><div></div><div></div><div></div></div>
</div></div>
</div>


        </>
      }

      </>
      
  )
}

export default Account