import React from 'react'
import './PurchesCourse.css'
import { useState } from 'react';
import { useAlert } from 'react-alert';
import Cookies from 'js-cookie';
import { CircularProgress } from "@mui/material";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import axios from 'axios'
import SideBar from '../../component/sidebar/SideBar';
import { apilink } from '../../data/fdata';
import { useEffect } from 'react';

const AddPurchesBooks = () => {
    const token = Cookies.get('_edushark_admin_access_token');
    const his = useHistory();
     const alert= useAlert()

    const [bookcategory, setBookCategory] = useState('');  
    const [booksem, setBookSem] = useState('');
    const [booklang, setBookLang] = useState('');  
    const [bookimage, setBookImage] = useState([]);
    const [bookname, setBookName] = useState('');
    const [bookauthor, setBookAuthor] = useState('');
    const [bookpublisher, setBookPublisher] = useState('');
    const [bookdimension_length, setBookDimension_length] = useState("");
    const [bookdimension_width, setBookDimension_width] = useState("");
    const [bookdimension_height, setBookDimension_height] = useState("");
    const [bookweight, setBookWeight] = useState("");
    const [bookpages, setBookPages] = useState("");  
    const [bookprice, setBookPrice] = useState('');
    const [booknumber, setBookNumber] = useState('');
    const [bookdiscount,setBookdiscount]=useState('')

  

 const [loading, setLoading] = useState(false);


  
  
  useEffect(async() => {
    if(token) {
      
        const res=await axios.get(`${apilink}/api/admin/authVerify`,{
            headers: {
              Authorization: token,
            },
          })
          // console.log(res.data)
          if(!res.data.success)
          {
            Cookies.remove('_edushark_admin_access_token');
           localStorage.removeItem('_edushark_admin_access_login');
          console.clear();
          window.location.href = '/login';
          }else{
           
            if(!res.data.userInfo?.isAdmin)
            {
              window.location.href = '/login';
            }
  
          }
          
         
    }else{
      his.push("/login")
    }
  }, []);

 const onSubmitForm=async(e)=>{
    e.preventDefault();
    setLoading(true)


    let formData = new FormData();
      
    Array.from(bookimage).forEach(image => {
      formData.append("file", image);
  });

  const res = await axios.post(`${apilink}/api/file/upload`, formData, {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: token,
    },
  });
  if(res.data.success)
  {
    const bookData = {
        bookcategory,
        booksem,
        booklang,
        bookimage:res.data.imageUrls,
        bookname,
        bookauthor,
        bookpublisher,
        bookdimension_length,
        bookdimension_width,
        bookdimension_height,
        bookweight,
        bookpages,
        bookprice,
        booknumber,
        bookdiscount
    };

   
  
    const ress=await axios.post(`${apilink}/api/purchesbook/postBook`,bookData,{
        headers: {
          
            Authorization: token,
          },
    });
    // console.log(ress.data)
    if(ress.data.success)
    {
        alert.success("Book added successfully")
        setTimeout(() => {
          his.push("/purchase-book")
        }, 1500);
    }else{
        alert.error(ress.data.msg)
    }

  }else{
    alert.error(res.data.msg)

  }

   
  
  
    setLoading(false)

 }

 const handelimage = (e) => {
    const { files } = e.target;
    let f = false;
    let filetype = ['image/jpeg', 'image/jpg', 'image/png'];
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        // console.log(files[i].type);
        if (files[i].size > 1000000) {
          f = true;
          // console.log('ok');
        }

        if (!filetype.includes(files[i].type)) {
          f = true;
          // console.log('hi');
        }
      }
      if (f) {
        // console.log(f);
        alert.error(
          'Upload images with proper file size (1MB) & extension ( jpg, png , jpeg)'
        );
        f = false;
      }else{
        // console.log();
        setBookImage(e.target.files)
      }
    }
  };


  
  return (
    <>
    <div className="whole__page">
        <div className="left_side_navbar">
          <SideBar />
        </div>
        <div className="main_div ">
          <div className="main_inner_div">
          <div className="container-fluid">
          <h3 className="text_custom">Add New Books</h3>
            <div className="row">
              <div className="col-12">
                <form className='mt-2' onSubmit={onSubmitForm}>

                <div class="form-row">
                     <div className="form-group col-lg-6 col-md-6">
                       <label for="address">Book Name:</label>
                       <input
                         type="text"
                         placeholder="Enter Book Name"
                         className="form-control form_h"
                         name="bookname"
                         value={bookname}
                         onChange={(e) => setBookName(e.target.value)}
                         required
                       />
                     </div>
                     <div className="form-group col-lg-6 col-md-6">
                       <label htmlFor=""> Author Name:</label>
                       <input
                         type="text"
                         placeholder="Enter Author Name"
                         className="form-control form_h"
                         name="bookauthor"
                         value={bookauthor}
                         onChange={(e) => setBookAuthor(e.target.value)}
                         required
                       />
                     </div>
                   </div>

                   <div class="form-row">
                   <div className="form-group col-lg-6 col-md-6">
                       <label for="address">Category:</label>
                       <select
                          class="form-control form_h"
                          value={bookcategory}
                          onChange={(e) => setBookCategory(e.target.value)}
                          required
                        >
                          <option selected hidden value="">
                            --Choose Category--
                          </option>
                          <option value="BED">B.ed</option>
                          <option value="DELED">D.el.ed</option>
                          <option value="DPHARM">D.pharm</option>
                          <option value="Others">Others</option>

                         
                        </select>
                       
                     </div>
                     <div className="form-group col-lg-6 col-md-6">
                       <label for="address">Semester or year:</label>
                       <select
                          class="form-control form_h"
                          value={booksem}
                          onChange={(e) => setBookSem(e.target.value)}
                          required
                        >
                          <option selected hidden value="">
                            --Choose Semester--
                          </option>
                          <option value="1st Semester">1st Semester</option>
                          <option value="2nd Semester">2nd Semester</option>
                          <option value="3rd Semester">3rd Semester</option>
                          <option value="4th Semester">4th Semester</option>
                          <option value="Others">Others</option>



                         
                         
                        </select>
                       
                     </div>
                   </div>
                   <div class="form-row">
                   <div className="form-group col-lg-6 col-md-6">
                       <label for="address">Language:</label>
                       <select
                          class="form-control form_h"
                          value={booklang}
                          onChange={(e) => setBookLang(e.target.value)}
                          required
                        >
                          <option selected hidden value="">
                            --Choose Language--
                          </option>
                          <option value="Bengali">Bengali</option>
                          <option value="English">English</option>
                          <option value="Others">Others</option>

                         
                        </select>
                       
                     </div>
                     <div className="form-group col-lg-6 col-md-6">
                       <label for="address">Upload Book Images:</label>
                       <input
                         type="file"
                        
                         className="form-control form_h"
                         name="file"
                         multiple
                         accept=".png, .jpeg , .jpg"
                        
                         onChange={handelimage}
                         required
                       />
                      
                     </div>
                   </div>

                   {/* <div class="form-row">
                  
                     <div className="form-group col-lg-4 col-md-4">
                       <label for="address">Publisher Name:</label>
                       <input
                         type="text"
                         placeholder="Enter Publisher Name"
                         className="form-control form_h"
                         name="bookpublisher"
                         value={bookpublisher}
                         onChange={(e) => setBookPublisher(e.target.value)}
                         required
                       />
                     </div>
                     <div className="form-group col-lg-4 col-md-4">
                       <label htmlFor=""> Book Price:</label>
                       <input
                         type="text"
                         placeholder="Enter Book Price"
                         className="form-control form_h"
                         name="bookprice"
                         value={bookprice}
                         onChange={(e) => setBookPrice(e.target.value)}
                         required
                       />
                     </div>
                     <div className="form-group col-lg-4 col-md-4">
                       <label htmlFor=""> Book Discount:</label>
                       <input
                         type="number"
                         placeholder="Enter Book Discount"
                         className="form-control form_h"
                         name="bookdiscount"
                         value={bookdiscount}
                         onChange={(e) => setBookdiscount(e.target.value)}
                         required
                       />
                     </div>
                   </div> */}


<div class="form-row">
                  
                  <div className="form-group col-lg-6 col-md-6">
                    <label for="address">Publisher Name:</label>
                    <input
                      type="text"
                      placeholder="Enter Publisher Name"
                      className="form-control form_h"
                      name="bookpublisher"
                      value={bookpublisher}
                      onChange={(e) => setBookPublisher(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group col-lg-6 col-md-6">
                    <label htmlFor=""> Book Price:</label>
                    <input
                      type="text"
                      placeholder="Enter Book Price"
                      className="form-control form_h"
                      name="bookprice"
                      value={bookprice}
                      onChange={(e) => setBookPrice(e.target.value)}
                      required
                    />
                  </div>
                  
                </div>
                   <p>Dimension</p>
                   <div class="form-row">
                     <div className="form-group col-lg-4 col-md-4">
                       <label for="address">Length:</label>
                       <input
                         type="number"
                         placeholder="Enter Length"
                         className="form-control form_h"
                         name="bookdimension_length"
                         value={bookdimension_length}
                         onChange={(e) => setBookDimension_length(e.target.value)}
                         required
                       />
                     </div>
                     <div className="form-group col-lg-4 col-md-4">
                       <label for="address">Width:</label>
                       <input
                         type="number"
                         placeholder="Enter Width"
                         className="form-control form_h"
                         name="bookdimension_width"
                         value={bookdimension_width}
                         onChange={(e) => setBookDimension_width(e.target.value)}
                         required
                       />
                     </div>
                     <div className="form-group col-lg-4 col-md-4">
                       <label for="address">Height:</label>
                       <input
                         type="number"
                         placeholder="Enter Height"
                         className="form-control form_h"
                         name="bookdimension_height"
                         value={bookdimension_height}
                         onChange={(e) => setBookDimension_height(e.target.value)}
                         required
                       />
                     </div>
                   </div>
                   <div class="form-row">
                     <div className="form-group col-lg-4 col-md-4">
                       <label for="address">Weight in Kg:</label>
                       <input
                         type="number"
                         placeholder="Enter Weight in Kg"
                         className="form-control form_h"
                         name="bookweight"
                         value={bookweight}
                         onChange={(e) => setBookWeight(e.target.value)}
                         required
                       />
                     </div>
                     <div className="form-group col-lg-4 col-md-4">
                       <label htmlFor=""> No of Pages:</label>
                       <input
                         type="number"
                         placeholder="Enter No of Pages"
                         className="form-control form_h"
                         name="bookpages"
                         value={bookpages}
                         onChange={(e) => setBookPages(e.target.value)}
                         required
                       />
                     </div>
                     <div className="form-group col-lg-4 col-md-4">
                       <label htmlFor=""> No of Books:</label>
                       <input
                         type="text"
                         placeholder="Enter No of Books"
                         className="form-control form_h"
                         name="booknumber"
                         value={booknumber}
                         onChange={(e) => setBookNumber(e.target.value)}
                         required
                       />
                     </div>
                   </div>

                   
                  

                   <div className="text-right">
                      <button
                        type="submit"
                        className={
                          loading
                            ? "dis btn btn-primary pl-5 pr-5"
                            : "btn btn-primary pl-5 pr-5"
                        }
                        disabled={loading}
                      >
                        Submit
                      </button>
                    </div>
                    
                    {loading && (
                      <div className="text-center p-2">
                        <CircularProgress size={35} />
                      </div>
                    )}
                   
                </form>
                
              </div>
            </div>

          </div>
         
           
          </div>
        </div>
      </div>
    </>
  )
}

export default AddPurchesBooks