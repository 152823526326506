import React, { useState } from 'react'
import './Auth.css'
import { CircularProgress } from '@mui/material'
import { apilink, path } from '../../data/fdata'
import { useAlert } from 'react-alert'
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie'
import parse from 'html-react-parser';


const ForgetPassword = () => {


  const alert = useAlert();
  const [email, setEmail] = useState('');
 
  const [col, setCol] = useState('');

  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const his = useHistory();

  const onSub = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res=await axios.post(`${apilink}/api/admin/forgetPassword`,{
      email
    })
    if(res.data.success)
    {
      setTimeout(() => {
        setStatus(true)
      setMsg(` Please check your Inbox or Spam , if did not received mail <span> <b>Click on Send Now</b> </span>`)
      setLoading(false);
      setCol("alert-success")
      }, 1500);

    }else{
      setTimeout(() => {
        setStatus(true)
      setMsg(res.data.msg)
      setLoading(false);
      setCol("alert-warning")
      }, 1500);
      
    }

    
  };

  return (
    <>
    
    
    
    <div className="auth">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-7 col-12 mx-auto">
              <div className="card p-3">
                {status ? (
                  <>
                    <div class={`alert ${col} alert-dismissible`}>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        onClick={() => setStatus(false)}
                      >
                        &times;
                      </button>
                      {parse(msg)}
                    </div>
                  </>
                ) : null}
<img
                    src={`${path}/image/logo.png`}
                    alt=""
                    className="logoimg"
                  />
                <h5 className="text-center pb-3">Forget Password</h5>
                <br />
                <form onSubmit={onSub} className="">
                  <div class="form-group">
                    <input
                      type="email"
                      placeholder="Enter Account Email"
                      class="form-control"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value.toLowerCase())}
                      required
                    />
                  </div>
                  <p className='m-0 cur fn_12 re_p' onClick={()=>his.push("/login")}>Back to login </p>
                 

                 
                  <div className="text-center">
                    <button
                      type="submit"
                      className={
                        loading ? 'dis sm_btn' : 'sm_btn'
                      }
                      disabled={loading}
                    >
                      Send Now
                    </button>
                  </div>
                  {loading && (
                    <div className="text-center p-2">
                      <CircularProgress  size={35} />
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgetPassword